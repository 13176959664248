//Components
import LessonCardSelection from "../../Commons/Cards/LessonCardSelection/LessonCardSelection"
import LinkButton from "../../Commons/Buttons/LinkButton/LinkButton"
//Libraries
import AliceCarousel from "react-alice-carousel"

//Hooks
import { useSelector } from "react-redux"

//Styles
import "./LessonsSlider.scss"

const LessonsSlider = ({ currentUnivers, selectLessons, page }) => {
    return (
        <div className={page == "prepage" ? `home-lesson-slider-wrapper small` : `home-lesson-slider-wrapper normal`}>

            {page === "prepage" && (
                <div className="title">Notre sélection du moment</div>
            )}
            {Object.keys(selectLessons).length > 0 && (
                <div
                    className={`${selectLessons.images.length < 3 ? " flex" : ""}`}>
                    {selectLessons.images.length < 3 &&
                        selectLessons.images.map(({ product }) => (
                            <div
                                key={product.id}
                                className={`lessons-slider-card-wrapper`}>
                                {product !== null && (
                                    <LessonCardSelection
                                        page={page}
                                        productId={product.id}
                                        urlImg={
                                            product.mainImage !== null &&
                                            product.mainImage !== undefined
                                                ? product.mainImage.url
                                                : ""
                                        }
                                        title={product.name}
                                        professor={product.author_name}
                                        originalPrice={(product.variants.length >  0 ?
                                           ( product.variants[0].originalPrice /
                                            100).toFixed(2)
                                            .toString()
                                            .replace(".", ",")
                                             : null
                                        )
}
                                        price={(product.variants.length > 0 ?  product.variants[0].price / 100 : null)
                                            .toFixed(2)
                                            .toString()
                                            .replace(".", ",")}
                                        pack={product.pack}
                                        parcours={product.parcours}
                                        coffret={product.coffret}
                                        reduction={
                                            product.variants.length > 0 ? 
                                            (product.variants[0]
                                                .originalPrice !==
                                            product.variants[0].price
                                                ? Math.floor(
                                                      ((product.variants[0]
                                                          .originalPrice -
                                                          product.variants[0]
                                                              .price) /
                                                          product.variants[0]
                                                              .originalPrice) *
                                                      100
                                                  )
                                                : 0)
                                                : 0
                                        }
                                        newer={product.isNew}
                                        time={product.duree}
                                        level={product.niveau}
                                        key={product.id}
                                        currentUnivers={currentUnivers === undefined ? product.universes[0].code : currentUnivers}
                                        productSlug={product.slug}
                                        variants={product.variants}
                                        buyOnce={product.buy_once}
                                        giftCard={product.giftCard}
                                        giftCardAmountConfigurable={
                                            product.giftCardAmountConfigurable
                                        }
                                        alt={
                                            product.mainImage !== null &&
                                            product.mainImage !== undefined
                                                ? product.mainImage.alt
                                                : ""
                                        }
                                    />
                                )}
                            </div>
                        ))}

                    {selectLessons.images.length >= 3 && (
                        <AliceCarousel
                            infinite
                            items={selectLessons.images.map(({ product }) => (
                                <div
                                    key={product}
                                    className={`lessons-slider-card-wrapper`}>
                                    {product !== null && (
                                        <LessonCardSelection
                                            page={page}
                                            productId={product.id}
                                            urlImg={
                                                product.mainImage !== null &&
                                                product.mainImage !== undefined
                                                    ? product.mainImage.url
                                                    : ""
                                            }
                                            title={product.name}
                                            professor={product.author_name}
                                            originalPrice={(product.variants.length >  0 ?
                                              (  product.variants[0].originalPrice /
                                                100)
                                                .toFixed(2)
                                                .toString()
                                                .replace(".", ",")
                                                 : null
                                            )}
                                            price={(product.variants.length > 0 ?  (product.variants[0].price / 100).toFixed(2).toString().replace(".", ",") : null)}
                                            pack={product.pack}
                                            parcours={product.parcours}
                                            coffret={product.coffret}
                                            reduction={
                                                product.variants.length > 0 ? 
                                                (product.variants[0]
                                                    .originalPrice !==
                                                product.variants[0].price
                                                    ? Math.floor(
                                                          ((product.variants[0]
                                                              .originalPrice -
                                                              product.variants[0]
                                                                  .price) /
                                                              product.variants[0]
                                                                  .originalPrice) *
                                                          100
                                                      )
                                                    : 0)
                                                    : 0
                                            }
                                            newer={product.isNew}
                                            time={product.duree}
                                            level={product.niveau}
                                            key={product.id}
                                            currentUnivers={currentUnivers === undefined ? product.universes[0].code : currentUnivers}
                                            productSlug={product.slug}
                                            variants={product.variants}
                                            buyOnce={product.buy_once}
                                            giftCard={product.giftCard}
                                            giftCardAmountConfigurable={
                                                product.giftCardAmountConfigurable
                                            }
                                            alt={
                                                product.mainImage !== null &&
                                                product.mainImage !== undefined
                                                    ? product.mainImage.alt
                                                    : ""
                                            }
                                        />
                                    )}
                                </div>
                            ))}
                            responsive={{
                                0: {
                                    items: 1,
                                },
                                500 : {
                                    items : 3,
                                },
                                1024: {
                                    items: 4,
                                },
                                1600: {
                                    items: 4,
                                },
                            }}
                        />
                    )}
                </div>
            )}

            {page !== "prepage" && (
                <div
                    onClick={() => {}}
                    className="home-lesson-slider-button-wrapper">
                        <LinkButton texte={"tous les cours"} url={"catalogue/tout/1"} />
                </div>
            )}

        </div>
    )
}

export default LessonsSlider
