// Components
import Logo from "../../Commons/Logo/Logo"
import DropDownMenu from "../../Commons/DropDownMenu/DropDownMenu"
import NavBar from "./NavBar/NavBar"

//Hooks
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { NavLink, useLocation } from "react-router-dom"
import MediaQuery from "react-responsive"
//Actions
import { toggleDropown, toggleBurger } from "../../../actions/prepageAction"

//Styles
import "./Header.scss"

//Data
import { dropdownFirstHome } from "../../../data/dropdownFirstHome"

const Header = () => {
    const location = useLocation()
    const dispatch = useDispatch()
    const isOpenDropdown = useSelector(
        (state) => state.prepage.dropDownMenuFirstHome
    )
    const isOpenBurger = useSelector((state) => state.prepage.toggleBurger)

    useEffect(() => {
        document.title = "Artesane : Cours et formations d'artisanat en ligne"
        const metaDescription = document.querySelector(
            "meta[name='description']"
        )
        metaDescription.setAttribute(
            "content",
            "Découvrez Artesane, première école en ligne dédiée aux savoir-faire artisanaux. Accédez à plus de 800 cours vidéo en couture, dessin, et bien plus."
        )
        const metaKeywords = document.querySelector("meta[name='keywords']")
        metaKeywords.setAttribute(
            "content",
            "cours de couture en ligne, cours de broderie en ligne, cours de tricot en ligne, couture, video couture online, cours de couture video, apprendre à coudre en ligne, modelisme, stylisme, CAP couture, cours de cuisine en ligne, arts culinaires, pâtisserie, macarons, macaron, tartes, tarte, cuisson, cuissons, cuisine végétale, vegan, végétarienne, végétalienne, cuisines asiatiques, cuisine japonnaise, cuisine chinoise, cuisine italienne, arts plastiques, céramique, dessin, dessins, aquarelle, apprendre à peindre, peinture, arts du fil, broderie or, haute couture, tricot, couture homme, tailleurs"
        )
        const metaOGDescription = document.querySelector(
            "meta[property='og:description']"
        )
        metaOGDescription.setAttribute(
            "content",
            "Découvrez Artesane, première école en ligne dédiée aux savoir-faire artisanaux. Accédez à plus de 800 cours vidéo en couture, dessin, et bien plus."
        )
        const metaOGTitle = document.querySelector("meta[property='og:title']")
        metaOGTitle.setAttribute("content", "Artesane : Cours et formations d'artisanat en ligne")
        const metaOGUrl = document.querySelector("meta[property='og:url']")
        metaOGUrl.setAttribute("content", "https://www.artesane.com/")
        const linkCanonical = document.querySelector("link[rel='canonical']")
        linkCanonical.setAttribute("href", "https://www.artesane.com/")
        const metaRobots = document.querySelector("meta[name='robots']")
        metaRobots.setAttribute("content", "index, follow")
    }, [dispatch])

    useEffect(() => {
        dispatch(toggleBurger(false))
    }, [dispatch, location])

    return (
        <header
            className="header"
            onMouseEnter={() => {
                dispatch(toggleDropown(false))
            }}>
            {/* <div className="dropDown-menu-wrapper">
                <DropDownMenu
                    firstItem={"Nos cours"}
                    secondItem={"v"}
                    navItems={dropdownFirstHome}
                    action={toggleDropown}
                    keyId="prepage"
                    isOpen={isOpenDropdown}
                />
            </div> */}
            {/* <div className='burger-prepage-menu-wrapper'> */}
            <div
                className={
                    isOpenBurger
                        ? "prepage-menu-responsive-burger-open"
                        : "prepage-menu-responsive-burger"
                }>
                <div
                    onClick={() => {
                        dispatch(toggleBurger(!isOpenBurger))
                    }}>
                    <div
                        className={
                            isOpenBurger
                                ? "burger-item-one"
                                : "burger-menu-cross-item"
                        }></div>
                    <div
                        className={
                            isOpenBurger
                                ? "burger-item-two"
                                : "burger-menu-cross-item"
                        }></div>
                    <div
                        className={
                            isOpenBurger
                                ? "burger-item-three"
                                : "burger-menu-cross-item"
                        }></div>
                </div>
            </div>
            {/* </div> */}
            <div
                className={
                    isOpenBurger
                        ? "prepage-menu-wrapper"
                        : "prepage-menu-wrapper-closed"
                }>
                <div className="prepage-menu-item">
                    <NavLink to={"/arts-du-fil"}>Arts du fil</NavLink>
                </div>
                <div className="prepage-menu-item">
                    <NavLink to={"/arts-culinaires"}>Arts culinaires</NavLink>
                </div>
                <div className="prepage-menu-item">
                    <NavLink to={"/arts-plastiques"}>Arts plastiques</NavLink>
                </div>
                <div className="prepage-menu-item">
                    <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://www.artesane.com/le-journal/artesane-formation-pro">
                        Espace formation pro
                    </a>
                </div>
            </div>
            <NavLink to={"/"}>
                <Logo />
            </NavLink>
            <MediaQuery minWidth={1000}>
                <div className="universe-items">
                    <a href="/arts-du-fil">Arts du fil</a>
                    <a href="/arts-plastiques">Arts plastiques</a>
                    <a href="/arts-culinaires">Arts culinaires</a>
                </div>    
            </MediaQuery>
        
            <NavBar />
        </header>
    )
}

export default Header
