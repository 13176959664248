import Banner from "../../Banner/Banner"
import Header from "../../Prepage/Header/Header"

//Styles
import "./Cgv.scss"
import { useEffect } from "react"

const Cgv = () => {
    useEffect(() => {
        document.title =
            "Artesane - Apprenez à créer | Conditions générales de vente et d' utilisation"
        const metaDescription = document.querySelector(
            "meta[name='description']"
        )
        metaDescription.setAttribute(
            "content",
            "Prenez des cours d'arts culinaires, d'arts du fil ou bien d'arts plastiques en ligne! Avec notre panel de cours, nous sommes votre premier atelier de loisirs créatifs exclusivement sur internet."
        )
        const metaKeywords = document.querySelector("meta[name='keywords']")
        metaKeywords.setAttribute(
            "content",
            "cours de couture en ligne, cours de broderie en ligne, cours de tricot en ligne, couture, video couture online, cours de couture video, apprendre à coudre en ligne, modelisme, stylisme, CAP couture, cours de cuisine en ligne, arts culinaires, pâtisserie, macarons, macaron, tartes, tarte, cuisson, cuissons, cuisine végétale, vegan, végétarienne, végétalienne, cuisines asiatiques, cuisine japonnaise, cuisine chinoise, cuisine italienne, arts plastiques, céramique, dessin, dessins, aquarelle, apprendre à peindre, peinture, arts du fil, broderie or, haute couture, tricot, couture homme, tailleurs"
        )
        const metaOGDescription = document.querySelector(
            "meta[property='og:description']"
        )
        metaOGDescription.setAttribute(
            "content",
            "Prenez des cours d'arts culinaires, d'arts du fil ou bien d'arts plastiques en ligne! Avec notre panel de cours, nous sommes votre premier atelier de loisirs créatifs exclusivement sur internet."
        )
        const metaOGTitle = document.querySelector("meta[property='og:title']")
        metaOGTitle.setAttribute(
            "content",
            "Artesane - Apprenez à créer | CGV "
        )
        const metaOGUrl = document.querySelector("meta[property='og:url']")
        metaOGUrl.setAttribute(
            "content",
            "https://www.artesane.com" + window.location.pathname
        )
        const linkCanonical = document.querySelector("link[rel='canonical']")
        linkCanonical.setAttribute(
            "href",
            "https://www.artesane.com" + window.location.pathname
        )
        const metaRobots = document.querySelector("meta[name='robots']")
        metaRobots.setAttribute("content", "index, follow")
    }, [])

    return (
        <div>
            <Banner />
            <Header />
            <div className="cgv-wrapper">
                <h1>
                    Conditions g&eacute;n&eacute;rales de vente et
                    d&rsquo;utilisation sp&eacute;cifiques aux formations
                    &agrave; distance{" "}
                </h1>

                <p>
                    {" "}
                    La poursuite de la navigation sur le site
                    https://www.artesane.com ainsi que sur la plateforme de
                    formation professionnelle d&eacute;velopp&eacute;e par
                    Moodle et op&eacute;r&eacute;e par Artesane vaut acceptation
                    sans r&eacute;serves des conditions g&eacute;n&eacute;rales
                    de vente et d&rsquo;utilisation qui suivent :{" "}
                </p>

                <p>
                    La version actuellement en ligne des conditions
                    d&rsquo;utilisation est la seule opposable pendant toute la
                    dur&eacute;e d&rsquo;utilisation du site et
                    jusqu&rsquo;&agrave; ce qu&rsquo;une nouvelle version la
                    remplace. le pr&eacute;sent document est
                    r&eacute;put&eacute; &agrave; jour : il est accessible
                    &agrave; tout moment pendant la navigation de
                    l&rsquo;Utilisateur. Le pr&eacute;sent document regroupe
                    l&rsquo;ensemble des conditions et stipulations applicables
                    &agrave; la navigation sur le site https://www.artesane.com
                    ainsi que sur la plateforme h&eacute;berg&eacute;e sur
                    https://formation.artesane.com
                </p>

                <h2> Pr&eacute;ambule :</h2>

                <p>
                    Les pr&eacute;sentes conditions g&eacute;n&eacute;rales
                    s&rsquo;appliquent entre :{" "}
                </p>

                <p>
                    {" "}
                    Artesane - propri&eacute;taire du site e-commerce
                    www.artesane.com et de la plateforme de formation
                    https://www.formation.artesane.com - Soci&eacute;t&eacute;
                    par Actions Simplifi&eacute;es au capital de 100 600&euro;
                    dont le si&egrave;ge est situ&eacute; au 33 rue du Hameau
                    75015 Paris, immatricul&eacute;e sous le num&eacute;ro
                    814557575 au RCS de Paris et ayant pour Pr&eacute;sidente
                    Madame Anne-Sarah Ballu-Samuel. Artesane est organisme de
                    formation dont la d&eacute;claration d&rsquo;activit&eacute;
                    enregistr&eacute;e sous le num&eacute;ro 11756024175
                    aupr&egrave;s du pr&eacute;fet de r&eacute;gion
                    d&rsquo;Ile-de-France .
                </p>

                <p>Ci-apr&egrave;s &laquo; la Soci&eacute;t&eacute; &raquo;</p>

                <p>Et</p>

                <p>
                    {" "}
                    Toute personne physique agissant &agrave; titre particulier
                    ou comme repr&eacute;sentant d&rsquo;une personne morale
                    visitant ou effectuant un achat via le site internet
                    https://www.artesane.com ou l&rsquo;application mobile
                    Artesane ou disposant d&rsquo;acc&egrave;s sur la plateforme
                    de formation professionnelle communiqu&eacute;e par
                    l&rsquo;&eacute;quipe administrative d&rsquo;Artesane,
                </p>

                <p>Ci-apr&egrave;s &laquo; l&rsquo;Utilisateur &raquo;</p>

                <p>
                    Le site et l&rsquo;application mobile www.artesane.com
                    offrent un catalogue de cours et de formations en lignes
                    pour toute personne physique souhaitant apprendre ou
                    progresser dans le domaine des arts et loisirs
                    cr&eacute;atifs et des travaux manuels et des m&eacute;tiers
                    de l&rsquo;artisanat. Ces formations sont disponibles par le
                    biais de contenus vid&eacute;o, de cours vid&eacute;o, de
                    documents de cours, de photos, de quizz, de tutoriaux
                    disponibles et consultables exclusivement sur leur &laquo;
                    compte &eacute;l&egrave;ve &raquo;, ou sur la plateforme
                    d&eacute;di&eacute;e &agrave; la formation professionnelle,
                    accessibles via une connexion internet. Le site
                    www.artesane.com &eacute;tant un espace de commerce
                    &eacute;lectronique, le client reconna&icirc;t &ecirc;tre
                    parfaitement inform&eacute; du fait que son accord
                    concernant le contenu des pr&eacute;sentes conditions
                    g&eacute;n&eacute;rales de vente ne n&eacute;cessite pas la
                    signature manuscrite de ce document. En outre, les clients
                    ayant contract&eacute; avec la soci&eacute;t&eacute;
                    Artesane dans le cadre de son activit&eacute;
                    d&rsquo;organisme de formation professionnelle, se voient
                    rappeler l&rsquo;existence des pr&eacute;sentes conditions
                    dans la convention conclue au moment de leur inscription. La
                    signature de la convention ou du contrat de formation
                    professionnelle emporte l&rsquo;acceptation pleine et
                    enti&egrave;re des pr&eacute;sentes conditions. Le client
                    est invit&eacute; &agrave; sauvegarder et imprimer
                    -s&rsquo;il le souhaite- les pr&eacute;sentes conditions
                    g&eacute;n&eacute;rales de vente, lesquelles font foi entre
                    les parties. Le client d&eacute;clare &ecirc;tre une
                    personne physique, majeure et avoir la pleine
                    capacit&eacute; juridique lui permettant de s&rsquo;engager
                    au titre des pr&eacute;sentes conditions
                    g&eacute;n&eacute;rales et souscrire ainsi des contrats en
                    droit fran&ccedil;ais.{" "}
                </p>

                <h2>1- D&eacute;finitions</h2>

                <p>
                    ADMINISTRATEUR.TRICE : d&eacute;signe toute personne en
                    charge de la gestion de la plateforme d&eacute;di&eacute;e
                    &agrave; la formation professionnelle et de ses
                    diff&eacute;rents types d&rsquo;utilisateur. Ce r&ocirc;le
                    permet de disposer de droits de gestion administratifs ou/et
                    p&eacute;dagogiques.
                </p>

                <p>
                    CONDITIONS G&Eacute;N&Eacute;RALES D&rsquo;UTILISATION ET DE
                    VENTE : d&eacute;signe l&rsquo;ensemble du pr&eacute;sent
                    document, &eacute;galement d&eacute;nomm&eacute; &laquo; CGU
                    &raquo;, &laquo; CGV &raquo;, &laquo; CGU-CGV &raquo; ou
                    &laquo; Conditions g&eacute;n&eacute;rales &raquo; de
                    mani&egrave;re &eacute;quivalente.
                </p>

                <p>
                    CONTENU : d&eacute;signe l&rsquo;ensemble des contenus
                    produits par Artesane ou l&rsquo;un de ses partenaires et
                    accessibles depuis le site et la plateforme de formation
                    &agrave; distance. Le contenu peut &ecirc;tre
                    dispens&eacute; sous diff&eacute;rents formats :{" "}
                </p>
                <ul>
                    <li>vid&eacute;o</li>
                    <li>PDF &agrave; t&eacute;l&eacute;charger</li>
                    <li>Quizz &agrave; r&eacute;aliser</li>
                    <li>
                        Exercices en r&eacute;alit&eacute; virtuelle &agrave;
                        effectuer
                    </li>
                    <li>
                        Commentaires et r&eacute;ponses apport&eacute;es sur le
                        forum
                    </li>
                    <li>Photos</li>
                    <li>Charte graphique et logos</li>
                </ul>
                <p>
                    Le contenu comprend aussi la structure de nos formations. Il
                    est entendu que cette liste et non exhaustive et peut
                    &ecirc;tre amen&eacute;e &agrave; &ecirc;tre enrichie par
                    l&rsquo;&eacute;volution technique et p&eacute;dagogique des
                    formations et de la plateforme.{" "}
                </p>

                <p>
                    COURS VID&Eacute;O : d&eacute;signe l&rsquo;ensemble des
                    modules vid&eacute;o et des documents mentionn&eacute;s dans
                    chaque fiche produit &eacute;tant vendus &agrave;
                    l&rsquo;unit&eacute; ou en pack sur l&rsquo;espace de
                    commerce en ligne h&eacute;berg&eacute; sur le site
                    https://www.artesane.com. Les cours vid&eacute;os sont
                    ensuite accessible dans l&rsquo;espace personnel de
                    l&rsquo;utilisateur sur le site.{" "}
                </p>

                <p>
                    ESPACES COMMUNAUTAIRES : d&eacute;signent l&rsquo;ensemble
                    des espaces permettant une interaction, un &eacute;change
                    entre les diff&eacute;rents types d&rsquo;utilisateurs :
                    &eacute;l&egrave;ves, professeurs, administrateurs. Ces
                    espaces peuvent rev&ecirc;tir entres autres la forme
                    d&rsquo;un forum, d&rsquo;un fil de discussion -&laquo;
                </p>

                <p>
                    &Eacute;DITEUR : d&eacute;signe le propri&eacute;taire et
                    repr&eacute;sentant unique du Site et de la plateforme, tel
                    que d&eacute;sign&eacute; dans les Mentions L&eacute;gales.{" "}
                </p>

                <p>
                    FORMATION PROFESSIONNELLE &Agrave; DISTANCE :
                    Ci-apr&egrave;s sont d&eacute;sign&eacute;es comme &laquo;
                    formations professionnelles &agrave; distance &raquo;, les
                    cursus de formation accessibles via notre outil
                    d&eacute;di&eacute; de formation en ligne et incluant un
                    suivi p&eacute;dagogique &agrave; distance assur&eacute;
                    pendant une dur&eacute;e contractualis&eacute;e entre
                    l&rsquo;&eacute;l&egrave;ve et notre organisme. Le contenu
                    de la formation professionnelle choisie par
                    l&rsquo;&eacute;l&egrave;ve -dont la candidature a
                    &eacute;t&eacute; accept&eacute;e par notre organisme- est
                    d&eacute;taill&eacute; dans la convention de formation
                    sign&eacute;e entre les parties avant le commencement de la
                    formation. Les formations professionnelles &agrave; distance
                    peuvent &ecirc;tre financ&eacute;es selon diff&eacute;rents
                    moyens. Dans le cas d&rsquo;un financement personnel, les
                    formations professionnelles &agrave; distance sont
                    r&eacute;gl&eacute;es directement sur le site artesane.com
                    apr&egrave;s acceptation de la candidature de
                    l&rsquo;&eacute;l&egrave;ve.
                </p>

                <p>
                    LES PARTIES : d&eacute;signe ensemble l&rsquo;&Eacute;diteur
                    et l&rsquo;Utilisateur.
                </p>

                <p>
                    LES PRODUITS ET SERVICES : d&eacute;signe tous
                    &eacute;ventuels produits et/ou services expos&eacute;s par
                    l&rsquo;&eacute;diteur sur le site.{" "}
                </p>

                <p>
                    PLATEFORME DE FORMATION : d&eacute;signe la plateforme
                    Moodle op&eacute;r&eacute;e par Artesane qui h&eacute;berge
                    les parcours de formation dites professionnelles, qui
                    peuvent &ecirc;tre certifiantes ou dipl&ocirc;mantes. La
                    plateforme n&rsquo;est accessible que dans le cadre
                    d&rsquo;une inscription valid&eacute;e par
                    l&rsquo;&eacute;quipe administrative d&rsquo;Artesane.{" "}
                </p>

                <p>
                    L&rsquo;UTILISATEUR : toute personne physique naviguant sur
                    le site artesane.com et la plateforme de formation
                    professionnelle sous son enti&egrave;re
                    responsabilit&eacute;.{" "}
                </p>

                <h2>2. OBJET</h2>

                <p>
                    Les pr&eacute;sentes conditions g&eacute;n&eacute;rales ont
                    pour objet de d&eacute;finir les droits et obligations des
                    parties dans le cadre de la vente &agrave; distance par la
                    soci&eacute;t&eacute; Artesane de formations en ligne sur
                    diff&eacute;rentes th&eacute;matiques, via diff&eacute;rents
                    supports en ligne (textes, photos, vid&eacute;os, tutoriaux,
                    quizz &#8230;). Ces cours sont accessibles depuis
                    l&rsquo;espace personnel de l&rsquo;utilisateur, soit sur le
                    site https://www.artesane.com, soit sur la plateforme de
                    formation et ce, apr&egrave;s paiement par le client de la
                    formule de cours s&eacute;lectionn&eacute;e et le cas
                    &eacute;ch&eacute;ant acceptation par les deux parties du
                    contrat de formation professionnelle.{" "}
                </p>

                <h2>3. INDISPONIBILIT&Eacute; DU SITE OU DE LA PLATEFORME</h2>

                <p>
                    Le site web www.artesane.com ou l&rsquo;acc&egrave;s
                    &agrave; un ou plusieurs services au sein du site tels que
                    l&rsquo;espace de vente en ligne ou la plateforme de
                    formation professionnelle, pourront, sans pr&eacute;avis ni
                    indemnit&eacute;, &ecirc;tre ferm&eacute;s temporairement ou
                    d&eacute;finitivement et ce, sans qu&rsquo;un Internaute
                    puisse pr&eacute;tendre &agrave; une quelconque
                    indemnit&eacute;. Artesane met par ailleurs tout en oeuvre
                    pour assurer la mise &agrave; disposition des services
                    assur&eacute;s sur le site et la plateforme.
                    N&eacute;anmoins, en cas d&rsquo;&eacute;v&eacute;nements ne
                    relevant pas du contr&ocirc;le d&rsquo;Artesane ou de force
                    majeure, Artesane ne saurait &ecirc;tre tenu pour
                    responsable de l&rsquo;interruption des services. Les
                    utilisateurs seront par ailleurs avertis en cas de pannes et
                    ou de sessions de maintenance n&eacute;cessaires au bon
                    fonctionnement du site et de la plateforme de formation.{" "}
                </p>

                <h2>4. LES COURS</h2>

                <h3>4.1. Formules et disponibilit&eacute;s des cours</h3>

                <p>
                    Les cours sont vendus &agrave; l&rsquo;unit&eacute;. Le
                    d&eacute;tail des cours ainsi que le contenu sommaire et les
                    conditions inh&eacute;rentes &agrave; chaque formation sont
                    pr&eacute;cis&eacute;s sur la fiche produit dudit cours. Ces
                    conditions restent disponibles dans le descriptif de
                    l&rsquo;offre visible sur le site www.artesane.com
                    L&rsquo;&eacute;l&egrave;ve utilisateur a acc&egrave;s aux
                    cours dans son compte &eacute;l&egrave;ve apr&egrave;s
                    paiement, conform&eacute;ment aux conditions
                    sp&eacute;cifi&eacute;es dans la fiche produit. Cependant
                    Artesane pourra mettre en maintenance le site, un ou
                    plusieurs cours, en cas de probl&egrave;me technique ou en
                    ayant pour objectif d&rsquo;am&eacute;liorer le service
                    technique fourni. Cela pourra entra&icirc;ner temporairement
                    une coupure de l&rsquo;acc&egrave;s aux diff&eacute;rentes
                    formations sans que cela ne donne droit &agrave; un
                    quelconque d&eacute;dommagement. Apr&egrave;s paiement, les
                    cours vid&eacute;o sont imm&eacute;diatement accessibles
                    dans l&rsquo;espace personnel de l&rsquo;&eacute;l&egrave;ve
                    utilisateur, d&egrave;s r&eacute;ception de la confirmation
                    de commande adress&eacute;e par la soci&eacute;t&eacute;.
                    L&rsquo;int&eacute;gralit&eacute; des cours figure sur
                    l&rsquo;espace personnel du client pour toute la
                    dur&eacute;e pr&eacute;vue par la formule
                    s&eacute;lectionn&eacute;e. Les parties conviennent que les
                    images d&rsquo;illustrations, les photographies ou les
                    vid&eacute;os des produits et services propos&eacute;s
                    &agrave; la vente n&rsquo;ont pas de valeur contractuelle.{" "}
                </p>

                <h3>4.2. Sauvegarde</h3>

                <p>
                    Conform&eacute;ment aux conditions g&eacute;n&eacute;rales
                    d&rsquo;utilisation, Artesane n&rsquo;a pour seule
                    obligation que la mise &agrave; disposition des cours sur le
                    compte &eacute;l&egrave;ve de l&rsquo;&eacute;l&egrave;ve
                    utilisateur. Les cours vid&eacute;o ne peuvent en aucun cas
                    &ecirc;tre sauvegard&eacute;s ni partag&eacute;s par
                    l&rsquo;Utilisateur. Ils restent la propri&eacute;t&eacute;
                    exclusive d&rsquo;Artesane. &Agrave; cet effet,
                    l&rsquo;utilisateur fera un usage strictement personnel des
                    notes prises en cours de formation sur les
                    &eacute;l&eacute;ments p&eacute;dagogiques enseign&eacute;s.
                    Les notes ne devront ni &ecirc;tre publi&eacute;s ni
                    &ecirc;tre transmises &agrave; des tiers &agrave; titre
                    gratuit ou on&eacute;reux.{" "}
                </p>

                <h3>4.3.Informations / Garanties</h3>

                <p>
                    plus grand soin est apport&eacute; &agrave; la mise en
                    ligne, pr&eacute;alablement &agrave; la passation de la
                    commande, d&rsquo;informations relatives notamment aux
                    caract&eacute;ristiques essentielles des cours mis en vente,
                    aux modalit&eacute;s de paiement. Toutefois, des variations
                    minimes dans la repr&eacute;sentation du ou des cours et ne
                    portant pas sur les caract&eacute;ristiques essentielles
                    peuvent &ecirc;tre pr&eacute;sentes. Ces variations ne
                    sauraient en aucun cas engager la responsabilit&eacute;
                    contractuelle d&rsquo;Artesane. L&rsquo;Utilisateur
                    d&eacute;clare avoir re&ccedil;u toutes les informations et
                    conseils utiles lui permettant de prendre, sous sa seule
                    responsabilit&eacute;, l&rsquo;int&eacute;gralit&eacute; des
                    d&eacute;cisions relatives au choix du ou des cours. Le
                    client a v&eacute;rifi&eacute; que le ou les cours sont
                    conformes &agrave; ses besoins et qu&rsquo;il dispose des
                    logiciels lui permettant d&rsquo;ouvrir et lire le format de
                    fichier associ&eacute; aux cours. Artesane ne garantit pas
                    l&rsquo;ad&eacute;quation des cours aux besoins du client
                    qui est seul responsable des choix effectu&eacute;s au
                    moment de sa commande ainsi que de l&rsquo;assimilation par
                    le client des cours qui d&eacute;pendent notamment de
                    l&rsquo;assiduit&eacute; et du s&eacute;rieux du client
                    &agrave; bien comprendre et utiliser les notions apprises
                    dans les cours.{" "}
                </p>

                <h2>5. LES FORMATIONS</h2>

                <h3>
                    5.1 Formules et disponibilit&eacute; des formations
                    professionnelles &agrave; distance
                </h3>

                <p>
                    Les formations professionnelles &agrave; distance sont
                    propos&eacute;es sous forme de cursus accessibles via la
                    plateforme de formation professionnelle. Ces cursus sont
                    accessibles &agrave; partir de la date indiqu&eacute;e dans
                    la convention de formation professionnelle. Leur contenu est
                    pr&eacute;sent&eacute; sur le site. Par ailleurs le
                    programme de formation et son d&eacute;roul&eacute; dans le
                    temps sont indiqu&eacute;s de mani&egrave;re
                    d&eacute;taill&eacute;e dans la convention de formation. Les
                    formations professionnelles &agrave; distance
                    pr&eacute;sent&eacute;es &agrave; la vente et
                    l&rsquo;utilisation sur le site www.artesane.com consistent
                    en des modules de formation d&rsquo;apprentissage en ligne
                    sous forme de vid&eacute;os, de questionnaires, de supports
                    p&eacute;dagogiques (photos, documents de cours) et
                    d&rsquo;un suivi personnalis&eacute; de l&rsquo;apprenant.
                    Chaque formation fait l&rsquo;objet d&rsquo;un descriptif
                    mentionnant :
                    <ul>
                        <li>L&rsquo;intitul&eacute; de la formation</li>
                        <li>La dur&eacute;e de la formation</li>
                        <li>
                            Les diff&eacute;rents outils de formation
                            utilis&eacute;s
                        </li>
                        <li>
                            Les supports de cours accessibles dans le cadre de
                            la formation
                        </li>
                        <li>Le prix du module principal</li>
                        <li>
                            Les modules compl&eacute;mentaires optionnels le cas
                            &eacute;ch&eacute;ant
                        </li>
                        <li>
                            Les &eacute;ventuels modules gratuits mis &agrave;
                            disposition du Client afin de lui permettre de
                            tester la formation
                        </li>
                        <li>
                            Les modalit&eacute;s de suivi et
                            d&rsquo;accompagnement de
                            l&rsquo;&Eacute;l&egrave;ve.
                        </li>
                        <li>
                            L&rsquo;acc&egrave;s aux formations professionnelles
                            &agrave; distance est donc conditionn&eacute; par la
                            convention conclue entre le Client et Artesane ou le
                            contrat conclu entre l&rsquo;Utilisateur et Artesane
                            : il est assur&eacute; dans les limites temporelles
                            et spatiales d&eacute;finies par cette
                            derni&egrave;re. Par ailleurs, les formations
                            &agrave; distance peuvent n&eacute;cessiter des
                            pr&eacute;-requis sur la qualit&eacute; de connexion
                            internet : par d&eacute;faut les formations
                            professionnelles &agrave; distance
                            n&eacute;cessitent une connexion stable.
                        </li>
                        <li>
                            La convention conclue est incessible. Aucun tiers ne
                            peut se substituer - gratuitement ou &agrave; titre
                            on&eacute;reux - &agrave; l&rsquo;Utilisateur dont
                            l&rsquo;identit&eacute; est indiqu&eacute;e sur la
                            convention. L&rsquo;Utilisateur s&rsquo;engage donc
                            &agrave; ne pas utiliser le compte d&rsquo;un autre
                            Utilisateur et &agrave; ne pas fournir &agrave; une
                            autre personne ses codes d&rsquo;acc&egrave;s
                            (adresse courriel et mot de passe) pour
                            acc&eacute;der &agrave; son compte. La pleine
                            responsabilit&eacute; de l&rsquo;Utilisateur est
                            engag&eacute;e concernant toutes les
                            activit&eacute;s qui se d&eacute;roulent dans son
                            espace personnel.
                        </li>
                        <li>
                            Les parties conviennent que les images
                            d&rsquo;illustrations, les photographies ou les
                            vid&eacute;os des produits et services
                            propos&eacute;s &agrave; la vente n&rsquo;ont pas de
                            valeur contractuelle.
                        </li>
                    </ul>
                </p>

                <h3>5.2 Sauvegarde</h3>

                <p>
                    Le contenu vid&eacute;o des formations professionnelles
                    &agrave; distance ne peut en aucun cas &ecirc;tre
                    sauvegard&eacute; ni partag&eacute; par l&rsquo;Utilisateur.
                    Il reste la propri&eacute;t&eacute; exclusive
                    d&rsquo;Artesane et doit &ecirc;tre utilis&eacute; dans le
                    strict respect du droit d&rsquo;auteur et de
                    propri&eacute;t&eacute; intellectuelle. Il est
                    rappel&eacute; &agrave; cette occasion que les photos ou les
                    captures d&rsquo;&eacute;cran du contenu de la formation ne
                    peuvent &ecirc;tre r&eacute;alis&eacute;es que dans le cadre
                    d&rsquo;un usage strictement personnel. Tout partage et/ou
                    commercialisation du contenu de la formation dans son
                    ensemble est passible de poursuites. &Agrave; cet effet,
                    l&rsquo;utilisateur fera un usage strictement personnel des
                    notes prises en cours de formation sur les
                    &eacute;l&eacute;ments p&eacute;dagogiques enseign&eacute;s.
                    Les notes ne devront ni &ecirc;tre publi&eacute;s ni
                    &ecirc;tre transmises &agrave; des tiers &agrave; titre
                    gratuit ou on&eacute;reux. Il est notamment reconnu et
                    accept&eacute; par l&rsquo;Utilisateur que
                    l&rsquo;utilisation de syst&egrave;mes automatis&eacute;s ou
                    de logiciels pour extraire les donn&eacute;es du site
                    (&laquo; capture de donn&eacute;es d&rsquo;&eacute;cran
                    &raquo; / &laquo; screen scraping &raquo; / &laquo; web
                    scraping &raquo; notamment), &agrave; quelques fins que ce
                    soit, est strictement interdite.
                </p>

                <h3>
                    5.3 Dur&eacute;e d&rsquo;acc&egrave;s et d&rsquo;utilisation
                </h3>

                <p>
                    Sauf dispositions particuli&egrave;res express&eacute;ment
                    accept&eacute;es par Artesane, les droits
                    d&rsquo;utilisation et d&rsquo;acc&egrave;s aux produits et
                    services propos&eacute;s sur la plateforme sont
                    conc&eacute;d&eacute;s pour une dur&eacute;e de 24 mois
                    &agrave; compter de la date mentionn&eacute;e sur la
                    convention de formation. En revanche l&rsquo;accompagnement
                    p&eacute;dagogique et la correction des devoirs sont
                    pr&eacute;vus sur une dur&eacute;e propre &agrave; chaque
                    formation et explicitement mentionn&eacute;e dans la
                    convention de formation sign&eacute;e par les parties.
                </p>

                <h3>5.4 Certificat de formation Artesane</h3>

                <p>
                    Celui-ci pourra &ecirc;tre d&eacute;livr&eacute; dans les
                    conditions suivantes : un taux de 100% de compl&eacute;tion
                    de la formation dans les d&eacute;lais impartis :
                    &eacute;tant pr&eacute;cis&eacute; que la compl&eacute;tion
                    est &eacute;valu&eacute;e par le cumul de deux
                    crit&egrave;res, d&rsquo;une part les activit&eacute;s
                    doivent &ecirc;tre indiqu&eacute;es comme termin&eacute;es
                    sur la plateforme et l&rsquo;Utilisateur doit avoir obtenu
                    la note suffisante aux diff&eacute;rents quizz et devoirs de
                    la formation. Le certificat attestant de la validation de la
                    formation compl&eacute;t&eacute;e est d&eacute;livr&eacute;
                    par Artesane via la plateforme Moodle qui h&eacute;berge les
                    parcours de formation Artesane.
                </p>

                <h2>6. TARIFS</h2>

                <p>
                    Artesane se r&eacute;serve le droit de modifier les tarifs
                    affich&eacute;s sur le Site &agrave; tout moment.
                </p>

                <h3>6.1 Prix des cours vid&eacute;o, cahiers et coffrets</h3>

                <p>
                    Les prix affich&eacute;s sur le site et l&rsquo;application
                    mobile Artesane sont toutes taxes comprises. Ils sont
                    indiqu&eacute;s clairement sur chacune des fiches
                    produit.Tout changement du taux applicable &agrave; la T.V.A
                    sera imm&eacute;diatement et automatiquement
                    r&eacute;percut&eacute;s sur les prix de tous les produits
                    mis en vente sur le site et l&rsquo;application mobile.
                    L&rsquo;&eacute;l&egrave;ve utilisateur recevra une
                    confirmation par courriel de sa commande sur laquelle le
                    montant total de la commande sera indiqu&eacute; toutes
                    taxes comprises. N&eacute;anmoins, ces prix TTC ne
                    comprennent en aucun cas les frais de communication
                    li&eacute;s &agrave; l&rsquo;utilisation du service de vente
                    en ligne et de consultation des cours vid&eacute;o qui
                    restent &agrave; l&rsquo;enti&egrave;re charge de
                    l&rsquo;&eacute;l&egrave;ve utilisateur.
                </p>

                <h3>
                    6.2 Prix des formations professionnelles &agrave; distance
                </h3>

                <p>
                    Les tarifs des formations professionnelles sont
                    affich&eacute;s sur le site et dans les brochures
                    d&rsquo;information. Ils peuvent n&eacute;anmoins varier en
                    fonction des modes de financement. Si des devis sont
                    &eacute;tablis, ils pourront comprendre une remise
                    commerciale. N&eacute;anmoins ces remises ne sont pas
                    accord&eacute;es automatiquement et d&eacute;pendent
                    notamment du mode de financement de l&rsquo;Utilisateur.
                    Aucune remise ne pourra &ecirc;tre exig&eacute;e par le
                    Client et son accord reste &agrave; la libre
                    appr&eacute;ciation d&rsquo;Artesane. De la m&ecirc;me
                    mani&egrave;re que pour les cours vid&eacute;o, les prix des
                    formations ne couvrent pas les frais de communication
                    li&eacute;s &agrave; l&rsquo;utilisation du service de vente
                    en ligne, &agrave; l&rsquo;acc&egrave;s &agrave; la
                    plateforme et &agrave; la consultation des formations :
                    ceux-ci restent &agrave; l&rsquo;enti&egrave;re charge de
                    l&rsquo;Utilisateur.
                </p>

                <h2>7. COMMANDE</h2>

                <h3>7.1 Processus de cr&eacute;ation de compte</h3>

                <p>
                    Afin d&rsquo;effectuer une commande et conform&eacute;ment
                    aux conditions g&eacute;n&eacute;rales d&rsquo;utilisation,
                    l&rsquo;&eacute;l&egrave;ve utilisateur a l&rsquo;obligation
                    de cr&eacute;er un compte sur www.artesane.com. Cette
                    cr&eacute;ation de compte est gratuite et n&eacute;cessite
                    la saisie d&rsquo;une adresse courriel active, d&rsquo;un
                    nom, d&rsquo;un pr&eacute;nom, d&rsquo;un identifiant et
                    d&rsquo;un mot de passe strictement personnel et
                    confidentiel. L&rsquo;utilisateur s&rsquo;engage &agrave; ne
                    pas les communiquer &agrave; de quelconques tiers. Il
                    incombe &agrave; l&rsquo;Utilisateur de mettre en &#339;uvre
                    les mesures de s&eacute;curit&eacute; n&eacute;cessaires
                    &agrave; la protection de son identifiant et de son mot de
                    passe. Tout acc&egrave;s aux fonctionnalit&eacute;s du Site,
                    et notamment aux services Artesane et aux contenus rendu
                    possible par l'utilisation de l&rsquo;identifiant et du mot
                    de passe est r&eacute;put&eacute; &ecirc;tre le fait de
                    l&rsquo;Utilisateur qui en est titulaire, avec les
                    cons&eacute;quences en mati&egrave;re de
                    responsabilit&eacute; qui en r&eacute;sultent. Le compte
                    permet l&rsquo;acc&egrave;s par le client aux services de
                    www.artesane.com et est accessible avec l&rsquo;adresse
                    courriel et le mot de passe du client.
                </p>

                <h3>
                    7.2 Prise de commande, confirmation, r&eacute;tractation et
                    annulation
                </h3>

                <h4>
                    7.2.1 Prise de commande concernant les produits dits &laquo;
                    loisirs &raquo;
                </h4>

                <p>
                    Les produits dits loisirs comprennent les cours
                    vid&eacute;o, les coffrets et les documents mat&eacute;riels
                    ou immat&eacute;riels vendus sur le Site.
                </p>
                <p>
                    Concernant les commandes de produits physiques à retirer dans les locaux de l'Editeur, l'Utilisateur s'engage à retirer sa commande dans <strong>les quatre semaines</strong> (jours ouvrés) suivant son achat. Passé ce délai, le produit physique non retiré par l'Utilisateur sera remis dans les stocks de l'Editeur
                </p>

                <p>
                    La prise de commande comprend les &eacute;tapes suivantes :
                    <ul>
                        <li>S&eacute;lection d&rsquo;un produit</li>
                        <li>
                            Cr&eacute;ation d&rsquo;un compte
                            &eacute;l&egrave;ve utilisateur. Remplir les champs
                            obligatoires des formulaires d&rsquo;identification
                            et/ou de renseignements. Le client atteste de la
                            v&eacute;racit&eacute; et de l&rsquo;exactitude des
                            informations ainsi transmises.
                        </li>
                        <li>
                            Connexion &agrave; son compte &eacute;l&egrave;ve
                            utilisateur
                        </li>
                        <li>Validation de la commande et paiement.</li>
                        <li>
                            R&eacute;ception d&rsquo;un accus&eacute; de
                            r&eacute;ception par voie &eacute;lectronique de sa
                            commande.
                        </li>
                        <li>
                            Acc&egrave;s imm&eacute;diats aux cours et documents
                            dans le compte &eacute;l&egrave;ve du client
                        </li>
                        <li>
                            Toute commande effectu&eacute;e par le client via
                            les &eacute;tapes pr&eacute;cit&eacute;es constitue
                            une acceptation irr&eacute;vocable des conditions
                            g&eacute;n&eacute;rales.
                        </li>
                    </ul>
                </p>

                <h4>
                    7.2.2 Prise de commande concernant les &laquo; formations
                    professionnelles &raquo;
                </h4>

                <p>
                    Le processus de commande d&rsquo;une formation
                    professionnelle passe par la validation de la candidature de
                    l&rsquo;utilisateur et l&rsquo;acceptation de son dossier
                    tant sur le plan administratif que p&eacute;dagogique par
                    Artesane mais aussi par les &eacute;ventuels organismes
                    abondant le financement de la formation. Les formations
                    financ&eacute;es partiellement ou int&eacute;gralement par
                    le biais du compte personnel de formation, de P&ocirc;le
                    Emploi, d&rsquo;un op&eacute;rateur de comp&eacute;tences ou
                    d&rsquo;un employeur ne sont r&eacute;put&eacute;es
                    command&eacute;es qu&rsquo;une fois que les devis ou
                    formalit&eacute;s de r&egrave;glements sp&eacute;cifiques
                    ont &eacute;t&eacute; effectu&eacute;s et valid&eacute;s par
                    les diff&eacute;rentes parties prenantes. Par ailleurs, une
                    convention de formation est &eacute;tablie entre un
                    organisme financer et Artesane. Dans le cadre d&rsquo;un
                    financement personnel, un contrat de formation
                    professionnelle est conclu entre l&rsquo;Utilisateur et
                    Artesane. Ces documents devront &ecirc;tre sign&eacute;es
                    afin que l&rsquo;inscription soit d&eacute;finitivement
                    valid&eacute;e. Les d&eacute;lais de r&eacute;tractation
                    l&eacute;gaux courront et la formation ne pourra donc
                    d&eacute;buter que quinze jours minimum apr&egrave;s la
                    signature du contrat ou de la convention. En cas de
                    r&egrave;glement de la prestation pris en charge par
                    l&rsquo;Op&eacute;rateur de comp&eacute;tences dont il
                    d&eacute;pend ou par son employeur, il appartient au Client
                    de :
                    <ul>
                        <li>
                            faire une demande de prise en charge avant le
                            d&eacute;but de la formation et de s'assurer
                            l'acceptation de sa demande ;
                        </li>
                        <li>
                            envoyer sans d&eacute;lai &agrave; Artesane une
                            copie de l&rsquo;accord de prise en charge ;
                        </li>
                        <li>
                            s'assurer de la bonne fin du paiement par
                            l'organisme qu'il aura
                            d&eacute;sign&eacute;.&#8232;En cas de paiement
                            partiel du montant de la formation par l'OPCO, le
                            solde sera factur&eacute; au Client. Si la
                            soci&eacute;t&eacute; Artesane n&rsquo;a pas
                            re&ccedil;u l&rsquo;acompte d prise en charge de
                            l'OPCO au 1er jour de la formation, le Client sera
                            factur&eacute; de l'int&eacute;gralit&eacute; du
                            co&ucirc;t de la formation, en respectant les
                            r&egrave;gles d&rsquo;acompte l&eacute;gales. Le cas
                            &eacute;ch&eacute;ant, le remboursement des avoirs
                            par la soci&eacute;t&eacute; Artesane est
                            effectu&eacute; sur demande &eacute;crite du Client
                            accompagn&eacute; d'un relev&eacute;
                            d'identit&eacute; bancaire original.
                        </li>
                    </ul>
                    Sous r&eacute;serve de l&rsquo;accomplissement
                    d&rsquo;&eacute;tapes sp&eacute;cifiques &agrave; chaque
                    produit et service offert par Artesane, les &eacute;tapes
                    d&eacute;crites ci-apr&egrave;s pour r&eacute;aliser une
                    commande sont syst&eacute;matiques :
                    <ul>
                        <li>
                            Information sur les caract&eacute;ristiques
                            essentielles des produits et services
                            propos&eacute;s ;
                        </li>
                        <li>
                            Choix du produit ou service, et le cas
                            &eacute;ch&eacute;ant de ses options ;{" "}
                        </li>
                        <li>
                            Cr&eacute;ation d&rsquo;un compte par le Client,
                            avec indication de ses donn&eacute;es essentielles ;
                            le Client doit &ecirc;tre une personne physique ou
                            morale. Les comptes cr&eacute;&eacute;s par des
                            robots ou des m&eacute;thodes automatiques sont
                            formellement interdits. Le Client devra fournir son
                            identit&eacute; compl&egrave;te, une adresse email
                            valide ainsi que toutes les informations
                            obligatoires n&eacute;cessaires pour la
                            cr&eacute;ation du compte en ligne sur la plateforme
                            ;
                        </li>
                        <li>
                            {" "}
                            Acceptation des mentions l&eacute;gales et des
                            mentions S&eacute;curit&eacute; et Vie priv&eacute;e
                            du site Internet;
                        </li>
                        <li>
                            V&eacute;rification des &eacute;l&eacute;ments de la
                            commande et, le cas &eacute;ch&eacute;ant,
                            correction des erreurs ;
                        </li>
                        <li>
                            Suivi des instructions pour le paiement, et paiement
                            des produits et services ;
                        </li>
                        <li>
                            Acceptation et signature &eacute;lectronique du
                            contrat de formation et acceptation des
                            pr&eacute;sentes Conditions G&eacute;n&eacute;rales
                            de Vente et d&rsquo;Utilisation par la m&ecirc;me
                            occasion;
                        </li>
                        <li>
                            Mise &agrave; disposition des produits et services
                            dans le respect des dates pr&eacute;vues &agrave; la
                            convention de formation.
                        </li>
                    </ul>
                    Aux fins de bonne r&eacute;alisation de la commande, et
                    conform&eacute;ment &agrave; l&rsquo;article 1366 du Code
                    civil, le Client s&rsquo;engage &agrave; fournir des
                    &eacute;l&eacute;ments d&rsquo;identification
                    v&eacute;ridiques. Le Vendeur se r&eacute;serve la
                    possibilit&eacute; de refuser la commande, par exemple pour
                    toute demande anormale, r&eacute;alis&eacute;e de mauvaise
                    foi, ou pour tout motif l&eacute;gitime.
                </p>

                <h3>
                    7.3 Confirmation de commande et droit de r&eacute;tractation
                </h3>

                <p>
                    Conform&eacute;ment &agrave; la r&eacute;glementation
                    applicable, les informations contractuelles feront
                    l&rsquo;objet d&rsquo;une confirmation par voie
                    &eacute;lectronique &agrave; l&rsquo;adresse courriel
                    indiqu&eacute;e par le client sur le bon de commande. Il
                    appartient au client, sous sa responsabilit&eacute;, de
                    conserver sur le support de son choix les informations
                    contractuelles. La commande ne devient d&eacute;finitive
                    qu&rsquo;apr&egrave;s r&egrave;glement de la totalit&eacute;
                    du prix par le client. Les cours vid&eacute;o
                    rel&egrave;vent de l&rsquo;exception au droit de
                    r&eacute;tractation puisqu&rsquo;il s&rsquo;agit d&rsquo;un
                    contenu num&eacute;rique &laquo; non fourni sur un support
                    mat&eacute;riel &raquo;. Le consommateur renonce donc
                    express&eacute;ment &agrave; son droit de
                    r&eacute;tractation d&egrave;s l&rsquo;achat et
                    l&rsquo;ouverture d&rsquo;un cours vid&eacute;o. Concernant
                    la formation professionnelle &agrave; distance, le droit de
                    r&eacute;tractation peut &ecirc;tre exerc&eacute;
                    qu&rsquo;avant le d&eacute;marrage effectif de la formation
                    dans les d&eacute;lais l&eacute;gaux (jusqu&rsquo;&agrave;
                    quatorze jours apr&egrave;s la signature du contrat ou de la
                    convention). Il ne peut plus &ecirc;tre exerc&eacute;
                    d&egrave;s la premi&egrave;re connexion &agrave; la
                    plateforme ou apr&egrave;s la date de d&eacute;marrage
                    contractuellement pr&eacute;vue. Toute formation
                    commenc&eacute;e est due en totalit&eacute; &agrave;
                    l&rsquo;issue de cette derni&egrave;re et n&eacute;cessite
                    un versement de 30% d&rsquo;acompte &agrave; l&rsquo;issue
                    du d&eacute;lai de r&eacute;tractation. La
                    Soci&eacute;t&eacute; se r&eacute;serve le droit
                    d&rsquo;annuler toute commande d&rsquo;un Client avec lequel
                    il existerait un litige relatif au paiement d&rsquo;une
                    commande ant&eacute;rieure.
                </p>

                <h3>7.4 Annulation</h3>

                <h4>7.4.1 du fait de l&rsquo;Utilisateur</h4>

                <p>
                    Les commandes pass&eacute;es sur le site loisirs sont non
                    annulables. En cas d&rsquo;erreur de commande d&rsquo;un
                    produit, prenez contact avec notre service contact
                    contact@artesane.com qui fera ses meilleurs efforts pour
                    vous proposer un &eacute;change sur les produits
                    mat&eacute;riels. Concernant la formation professionnelle,
                    les conditions de r&eacute;tractation sont rappel&eacute;es
                    ci-dessus. L&rsquo;annulation doit intervenir dans le
                    d&eacute;lai l&eacute;gal de r&eacute;tractation.
                </p>

                <h4>7.4.2 du fait d&rsquo;Artesane</h4>

                <p>
                    Si une formation professionnelle &agrave; distance devait
                    &ecirc;tre annul&eacute;e du fait d&rsquo;Artesane en amont
                    de la formation, l&rsquo;Utilisateur et/ou le payeur
                    pourrait pr&eacute;tendre &agrave; un remboursement
                    int&eacute;gral de cette derni&egrave;re. En revanche aucune
                    annulation par Artesane ne donne droit &agrave; une
                    indemnit&eacute; compensatrice d&rsquo;aucune sorte.
                </p>

                <h2>8. MODALIT&Eacute;S DE PAIEMENT</h2>

                <h3>8.1 Moyens de paiement</h3>

                <p>
                    Le paiement peut &ecirc;tre effectu&eacute; en ligne via
                    carte bancaire. La soci&eacute;t&eacute; utilise les
                    services du prestataire de paiement Payplug et de son
                    compl&eacute;ment Oney. Le d&eacute;bit de la carte est
                    effectu&eacute; au moment de la commande sauf dans le cadre
                    des paiements en quatre fois op&eacute;r&eacute;s par Oney
                    et activables pour un montant d&rsquo;achat minimum de 100
                    euros pour les cours vid&eacute;o. Le Client peut effectuer
                    le r&egrave;glement par carte de paiement. Les cartes
                    &eacute;mises par des banques domicili&eacute;es hors de
                    France doivent obligatoirement &ecirc;tre des cartes
                    bancaires internationales (Mastercard ou Visa). Le paiement
                    s&eacute;curis&eacute; en ligne par carte bancaire est
                    r&eacute;alis&eacute; par le prestataire de paiement du
                    Vendeur. Les informations transmises sont chiffr&eacute;es
                    dans les r&egrave;gles de l&rsquo;art. Une fois le paiement
                    lanc&eacute; par le Client, la transaction est
                    imm&eacute;diatement d&eacute;bit&eacute;e apr&egrave;s
                    v&eacute;rification des informations. En communiquant ses
                    informations bancaires lors de la vente, le Client autorise
                    le Vendeur &agrave; d&eacute;biter sa carte du montant
                    relatif au prix indiqu&eacute;. Le Client confirme
                    qu&rsquo;il est bien le titulaire l&eacute;gal de la carte
                    &agrave; d&eacute;biter et qu&rsquo;il est l&eacute;galement
                    en droit d&rsquo;en faire usage. En cas d&rsquo;erreur, ou
                    d&rsquo;impossibilit&eacute; de d&eacute;biter la carte, la
                    Vente est imm&eacute;diatement r&eacute;solue de plein droit
                    et la commande annul&eacute;e. L&rsquo;Utilisateur
                    s&rsquo;engage &agrave; respecter et souscrire
                    enti&egrave;rement et express&eacute;ment aux conditions
                    g&eacute;n&eacute;rales d&rsquo;utilisation des
                    soci&eacute;t&eacute;s Payplug et Oney d&egrave;s lors
                    qu&rsquo;il passe une commande sur le site Artesane. Dans le
                    cadre des formations professionnelles, les formations
                    peuvent &ecirc;tre r&eacute;gl&eacute;es :
                    <ul>
                        <li>en ligne par carte bancaire ;</li>
                        <li>
                            Par virement bancaire du Client ou de
                            l&rsquo;organisme payeur ;
                        </li>
                    </ul>
                    Le cas &eacute;ch&eacute;ant et pour les formations
                    &eacute;ligibles seulement, par le biais du compte formation
                    professionnelle. Les formations concern&eacute;es sont
                    r&eacute;f&eacute;renc&eacute;es directement sur le
                    catalogue accessible ici :{" "}
                    <a href="https://www.moncompteformation.gouv.fr/espace-prive/html/#/">
                        https://www.moncompteformation.gouv.fr/espace-prive/html/#/
                    </a>{" "}
                </p>

                <h3>8.2. Propri&eacute;t&eacute;</h3>

                <p>
                    Les cours et les formations professionnelles mis &agrave;
                    disposition du client demeurent la propri&eacute;t&eacute;
                    de Artesane au titre du droit d&rsquo;auteur. Ils ne peuvent
                    donc &ecirc;tre copi&eacute;s, transmis ou diffus&eacute;s.
                    Ils ne sont ainsi mis &agrave; disposition de
                    l&rsquo;Utilisateur que pour son seul et unique usage
                    personnel. L&rsquo;Utilisateur acc&egrave;de aux cours et
                    aux formations dans le respect des conditions
                    g&eacute;n&eacute;rales d&rsquo;utilisation, auxquelles il
                    souscrit enti&egrave;rement d&egrave;s sa premi&egrave;re
                    commande.
                </p>

                <h2>9. PROPRI&Eacute;T&Eacute; INTELLECTUELLE</h2>

                <h3>9.1 G&eacute;n&eacute;ralit&eacute;s</h3>

                <p>
                    Conform&eacute;ment aux conditions g&eacute;n&eacute;rales
                    d&rsquo;utilisation, le site web, l&rsquo;application
                    mobile, les marques, les documents de cours, les dessins,
                    les mod&egrave;les, les photos, les images, les
                    bande-annonces, les cours vid&eacute;o, les textes, la
                    charte graphique, le logo, les tutoriels etc. (liste non
                    exhaustive) sont la propri&eacute;t&eacute; exclusive de la
                    soci&eacute;t&eacute; Artesane. Les conditions
                    g&eacute;n&eacute;rales de vente n&rsquo;emportent aucune
                    cession d&rsquo;aucune mani&egrave;re que ce soit des droits
                    de propri&eacute;t&eacute; intellectuelle et des droits
                    d&rsquo;auteurs sur les &eacute;l&eacute;ments appartenant
                    &agrave; la soci&eacute;t&eacute; au b&eacute;n&eacute;ficie
                    de l&rsquo;&eacute;l&egrave;ve utilisateur. L&rsquo;ensemble
                    des cours vid&eacute;o, leur exploitation et leur diffusion
                    sont strictement r&eacute;serv&eacute;s &agrave; la
                    soci&eacute;t&eacute; au titre du droit d&rsquo;auteur.
                </p>

                <h3>9.2. Licence</h3>

                <p>
                    Les cours et les formations professionnelles &agrave;
                    distance sont r&eacute;serv&eacute;s aux personnes physiques
                    ou morales pour un usage exclusivement personnel et
                    nominatif. Sont exclus de la licence, les organismes de
                    formation ou organisations collectives.
                </p>

                <h2>10. RESPONSABILIT&Eacute;</h2>

                <p>
                    Artesane ne saurait &ecirc;tre tenue pour responsable de
                    l&rsquo;inex&eacute;cution de ses obligations due &agrave;
                    un cas de force majeur au sens g&eacute;n&eacute;ralement
                    accept&eacute; par la loi et les tribunaux fran&ccedil;ais
                    ou pour des causes &eacute;chappant &agrave; son
                    contr&ocirc;le (contraintes et limites du r&eacute;seau
                    internet, panne r&eacute;seau, lenteur ou interruption du
                    fournisseur d&rsquo;acc&egrave;s internet du client&#8230;).
                    La Soci&eacute;t&eacute; fait ses meilleurs efforts afin que
                    le Site, son contenu et les service Artesane soient
                    accessibles 24h/24 et 7jours/7, sans toutefois &ecirc;tre
                    tenue &agrave; une obligation de r&eacute;sultat &agrave;
                    cet &eacute;gard. L&rsquo;Utilisateur reconna&icirc;t que la
                    Soci&eacute;t&eacute; ne saurait lui garantir que le Site et
                    les Services Artesane r&eacute;pondront &agrave; ses
                    attentes, ni qu&rsquo;ils ne seront pas affect&eacute;s par
                    des erreurs, anomalies, ou d&eacute;fauts mineurs.
                    L&rsquo;Utilisateur reconna&icirc;t que, nonobstant
                    l'ensemble des moyens techniques mis en &#339;uvre par
                    Artesane et ses partenaires, le r&eacute;seau Internet
                    pr&eacute;sente des sp&eacute;cificit&eacute;s techniques ne
                    mettant pas en mesure Artesane de garantir la
                    continuit&eacute; absolue de l'acc&egrave;s au Site, son
                    contenu et les Services Artesane. Artesane ne sera notamment
                    pas responsable d&rsquo;un quelconque dysfonctionnement
                    et/ou indisponibilit&eacute; du Site, son contenu et des
                    Services Artesane dans les hypoth&egrave;ses suivantes :
                    <ul>
                        <li>
                            utilisation du Site, son contenu et des Services
                            Artesane de mani&egrave;re non conforme &agrave;
                            leur destination,
                        </li>
                        <li>
                            manquement de l&rsquo;Utilisateur &agrave; ses
                            obligations au titre des pr&eacute;sentes conditions
                            g&eacute;n&eacute;rales,
                        </li>
                        <li>
                            d&eacute;faillance ou insuffisance des
                            r&eacute;seaux de communication &eacute;lectronique,
                        </li>
                        <li>
                            pendant les p&eacute;riodes de maintenance et
                            d&rsquo;actions correctives qui resteront
                            ponctuelles,
                        </li>
                        <li>
                            en cas de piratage du Site ou des Services Artesane
                            par un tiers,
                        </li>
                        <li>
                            dysfonctionnement et/ou indisponibilit&eacute;
                            imputable au fait, impr&eacute;visible et
                            insurmontable, d&rsquo;un tiers,
                        </li>
                        <li>cas de force majeure.</li>
                    </ul>
                    Enfin, l&rsquo;Utilisateur reconna&icirc;t &eacute;galement
                    que les transmissions de donn&eacute;es sur le r&eacute;seau
                    Internet sont d'une fiabilit&eacute; technique relative et
                    que les donn&eacute;es elles-m&ecirc;mes ne sont pas
                    prot&eacute;g&eacute;es contre des d&eacute;tournements
                    &eacute;ventuels, hormis dans le cadre du paiement
                    s&eacute;curis&eacute;. Il appartient donc &agrave;
                    l&rsquo;Utilisateur de prendre toutes les mesures
                    appropri&eacute;es de fa&ccedil;on &agrave; prot&eacute;ger
                    ses donn&eacute;es ainsi que son &eacute;quipement, de la
                    contamination par des virus comme des tentatives d'intrusion
                    dans son syst&egrave;me informatique par des tiers.
                    L&rsquo;Utilisateur reconna&icirc;t que son
                    &eacute;quipement est connect&eacute; au r&eacute;seau
                    Internet sous son enti&egrave;re responsabilit&eacute; et
                    qu'en cons&eacute;quence Artesane n'est en rien responsable
                    de tout dommage pouvant survenir durant sa connexion.
                </p>

                <h2>11 . PUBLICATIONS ELECTRONIQUES</h2>

                <p>
                    L&rsquo;Utilisateur d&eacute;clare et atteste sur
                    l&rsquo;honneur &ecirc;tre propri&eacute;taire pour avoir
                    les droits n&eacute;cessaires sur le contenu qu&rsquo;il
                    publie sur le site ou sur la plateforme de formation, sur
                    les fils de discussions et forums &agrave; sa disposition
                    ainsi que via la messagerie personnelle de son espace
                    d&eacute;di&eacute; sur la plateforme de formation. Il est
                    interdit aux Utilisateurs de t&eacute;l&eacute;charger,
                    d&rsquo;envoyer, de transmettre, de diffuser sur le Site ou
                    sur la Plateforme des contenus :
                    <ul>
                        <li>
                            susceptibles de porter atteinte &agrave; la
                            dignit&eacute; de la personne humaine;
                        </li>
                        <li> caract&egrave;re injurieux ou diffamatoire ;</li>
                        <li>
                            caract&egrave;re pornographique, p&eacute;dophile ou
                            p&eacute;dopornographique ;
                        </li>
                        <li>
                            Faisant l&rsquo;apologie de crimes contre
                            l&rsquo;humanit&eacute; ;
                        </li>
                        <li>
                            Incitant &agrave; la commission de crimes et
                            d&eacute;lits ;
                        </li>
                        <li>
                            Incitant &agrave; la haine raciale ou &agrave; la
                            discrimination ;
                        </li>
                        <li>
                            Portant atteinte &agrave; la vie priv&eacute;e et/ou
                            au droit &agrave; l&rsquo;image de toute personne;
                        </li>
                        <li>
                            Portant atteinte aux droits de
                            propri&eacute;t&eacute; intellectuelle ou droits
                            voisins d&rsquo;un tiers ;
                        </li>
                        <li>
                            Portant atteinte aux droits de producteurs de bases
                            de donn&eacute;es ;
                        </li>
                        <li>
                            Constitutifs de publicit&eacute; trompeuse ou
                            comparative Faisant la publicit&eacute; du tabac, de
                            l&rsquo;alcool ou de m&eacute;dicaments
                        </li>
                        <li>
                            Contenant un virus ou tout autre programme
                            susceptible d&rsquo;endommager, de d&eacute;truire
                            ou d&rsquo;alt&eacute;rer les syst&egrave;mes,
                            programmes, fichiers informatiques ou donn&eacute;es
                            d&rsquo;Artesane et/ou de ses clients
                        </li>
                        <li>
                            De mani&egrave;re g&eacute;n&eacute;rale, &agrave;
                            caract&egrave;re illicite.
                        </li>
                    </ul>
                    Cette interdiction s&rsquo;applique &eacute;galement aux
                    contenus auxquels donnent acc&egrave;s les liens hypertextes
                    fournis par l&rsquo;un des Utilisateurs du site
                    www.artesane.com et de la Plateforme. Par ailleurs dans le
                    cadre de l&rsquo;utilisation des diff&eacute;rents espaces
                    communautaires mis &agrave; disposition sur le Site et la
                    Plateforme, l&rsquo;Utilisateur autorise express&eacute;ment
                    Artesane &agrave; partager le contenu qu&rsquo;il a mis
                    librement &agrave; disposition. Ce contenu pourra &ecirc;tre
                    utilis&eacute; &agrave; des fins p&eacute;dagogiques ou
                    publicitaires. Conform&eacute;ment &agrave; la loi
                    n&deg;2004-575 du 21 juin 2004 pour la confiance dans
                    l&rsquo;&Eacute;conomie num&eacute;rique, Artesane rappelle
                    que les Utilisateurs ont la possibilit&eacute; de notifier
                    tout contenu litigieux par courriel &agrave;
                    contact@artesane.com
                </p>

                <h2>12. DUR&Eacute;E</h2>

                <p>
                    Les pr&eacute;sentes conditions s&rsquo;appliquent pendant
                    toute la dur&eacute;e de mise en ligne des services offerts
                    par Artesane.
                </p>

                <h2>12. PREUVE</h2>

                <p>
                    Les registres informatis&eacute;s, conserv&eacute;s sur les
                    serveurs de Artesane ou sur ceux de ses h&eacute;bergeurs,
                    seront consid&eacute;r&eacute;s comme les
                    &eacute;l&eacute;ments de preuve des communications, des
                    commandes et des paiements intervenus entre les parties.
                </p>

                <h2>
                    14. POLITIQUE DE CONFIDENTIALIT&Eacute; ET USAGE DES
                    DONN&Eacute;ES PERSONNELLES
                </h2>

                <p>
                    La politique de confidentialit&eacute; et les droits de
                    l&rsquo;Utilisateur en mati&egrave;re de traitement des
                    donn&eacute;es personnelles sont rappel&eacute;s ici{" "}
                    <a href="https://www.artesane.com/politique-de-confidentialite">
                        https://www.artesane.com/politique-de-confidentialite
                    </a>
                </p>

                <h2>15. M&Eacute;DIATION </h2>

                <p>
                    Conform&eacute;ment &agrave; l&rsquo;article L.612-1 du Code
                    de la consommation, dans un d&eacute;lai d&rsquo;un an
                    &agrave; compter de sa r&eacute;clamation &eacute;crite, le
                    consommateur, sous r&eacute;serve de l&rsquo;article L.152-2
                    du code pr&eacute;cit&eacute;, a la facult&eacute;
                    d&rsquo;introduire une demande de r&eacute;solution amiable
                    par voie de m&eacute;diation.{" "}
                </p>

                <p>
                    CNPM M&eacute;diation Consommation SAS 27 avenue de la
                    Lib&eacute;ration 42400 Saint-Chamond Courriel :
                    contact-admin@cnpm-mediation-consommation.eu T&eacute;l : +
                    33 (0)9 88 30 27 72
                </p>

                <h2>13. DROIT APPLICABLE</h2>
                <p>
                    Les pr&eacute;sentes conditions sont soumises
                    g&eacute;n&eacute;rales sont soumises &agrave; la loi
                    fran&ccedil;aise. Il en est ainsi pour les r&egrave;gles de
                    fond comme pour les r&egrave;gles de forme. En cas de
                    contestation, le litige sera, &agrave; d&eacute;faut
                    d&rsquo;accord amiable, de la comp&eacute;tence exclusive
                    des tribunaux comp&eacute;tents fran&ccedil;ais.
                </p>

                <p>Version des CGV-U en vigueur au 12 mai 2023 </p>
            </div>
        </div>
    )
}
export default Cgv
