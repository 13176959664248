import AliceCarousel from "react-alice-carousel"
import MenuCard from "./MenuCard/MenuCard";
import './MenuCards.scss';
import { useSelector } from "react-redux";

const MenuCards = () => {

    const menuSliderPrepage = useSelector((state) => state.common.menuSliderPrepage);
    return (
        <div>
        {Object.keys(menuSliderPrepage).length > 0 && (
                <div
                    className={`${menuSliderPrepage.images.length < 3 ? " flex" : ""}`}>
                    {menuSliderPrepage.images.length < 3 &&
                        menuSliderPrepage.images.map(({ id, link, linktext, url }) => (
                            <MenuCard
                            link = {link} 
                            textLink = {linktext} 
                            urlImg = {url !== undefined ? url : ""}
                            />
                        ))}

                    {menuSliderPrepage.images.length >= 3 && (
                        <div className="menucards-wrapper"> 
                        <AliceCarousel
                            infinite
                            items={menuSliderPrepage.images.map(({ id, link, linktext, url }) => (
                                <MenuCard
                                link = {link} 
                                textLink = {linktext} 
                                urlImg = {url !== undefined ? url : "https://media.artesane.com/media/cache/artesane_original/43/f4/bbee24b3b6c697a4be0d90b916dd.jpg.webp"}
                                />
                            ))}
                            responsive={{
                                0: {
                                    items: 1,
                                },
                                500 : {
                                    items : 3,
                                },
                                1024: {
                                    items: 4,
                                },
                                1600: {
                                    items: 4,
                                },
                            }}
                        />
                        </div>

                    )}
                </div>
            )}
        </div>
    )
}

export default MenuCards;