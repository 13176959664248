//Styles
import "./Reinsurance.scss"

const Reinsurance = ({
    logo,
    altImg,
    text,
    underText,
    styledColor,
}) => (
    <div className="reinssurance-items">
        <img className="pictogram" src={logo} alt={altImg} />
        <div className="text-wrapper">
            <div className={`catchPhrase ${styledColor}`}>
                <h4>{text} </h4>
                <div>{underText}</div>
            </div>
        </div>
    </div>
)

export default Reinsurance
