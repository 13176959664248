
import { NavLink } from 'react-router-dom';
import './Newsletterform.scss';
import { useSelector } from 'react-redux';

const NewsletterForm = () => {

    const newsletterComponentTitle = useSelector((state) => state.common.newsletterComponentTitle);
    const newsletterComponentSubtitle = useSelector((state) => state.common.newsletterComponentSubtitle);
    return (
        <div className="newsletterform-element-wrapper">
            <div className="newsletterform-background-wrapper">
                <div className="newsletterform-wrapper">
                    <div className='newsletterform-left-side'>
                        <div>
                            <div className='uppercase'>
                               {newsletterComponentTitle} 
                            </div>
                            <div>
                                {newsletterComponentSubtitle}
                            </div>
                        </div>
                        <div className='details'>
                        Artesane respecte vos données personnelles. Vous pouvez vous désinscrire à tout moment et retrouver notre <NavLink to="/politique-de-confidentialite">politique de confidentialité</NavLink>
                        </div>

                     
                    </div>
                    <form
                        name="mc-embedded-subscibe-form"
                        rel={"noopener"}
                        // noValidate
                        action="https://artesane.us13.list-manage.com/subscribe/post?u=fed3adae0edf9e9d89854e25d&amp;id=698c1acbba&amp;f_id=00f3c2e1f0"
                        target="_blank"
                        method="post"
                        className="newsletter-footer-form"
                        id="mc-embedded-subscribe-form">
                        <div className="input-top-wrapper">
                            <input
                                className="newsletter-footer-input"
                                placeholder="Mon e-mail"
                                type="email"
                                id="mce-EMAIL"
                                size="30"
                                name={"EMAIL"}
                                required
                            />


                        <div>
                            <ul>
                                <li>
                                    <input
                                        type="checkbox"
                                        value="2"
                                        name="group[15][2]"
                                        id="mce-group[15]-15-0"
                                    />
                                    <label htmlFor="mce-group[15]-15-0">
                                        Arts du fil
                                    </label>
                                </li>
                                <li>
                                    <input
                                        type="checkbox"
                                        value="4"
                                        name="group[15][4]"
                                        id="mce-group[15]-15-1"
                                    />
                                    <label htmlFor="mce-group[15]-15-1">
                                        Arts plastiques
                                    </label>
                                </li>
                                <li>
                                    <input
                                        type="checkbox"
                                        value="8"
                                        name="group[15][8]"
                                        id="mce-group[15]-15-2"
                                    />
                                    <label htmlFor="mce-group[15]-15-2">
                                        Arts culinaires
                                    </label>
                                </li>
                            </ul>
                        </div>

                            <button
                                className="newsletter-footer-button"
                                type="submit">
                                je m'inscris
                            </button>
                        </div>


                    </form>
                </div>
            </div>
        </div>
    )
}


export default NewsletterForm;