import React, { useEffect, useRef, useState } from 'react';
import './MainCard.scss';
import SearchBarMutual from '../../../Home/Header/UnderNavBar/SearchBarMutual/SearchBarMutual';
import { changeSearchbar } from '../../../../actions/prepageAction';
import { useDispatch, useSelector } from 'react-redux';

const MainCard = ({ h1, description }) => {
  const h1Ref = useRef(null);
  const searchRef = useRef(null);
  const [widthPercent, setWidthPercent] = useState('100%');
  const [widthSearchPercent, setWidthSearchPercent] = useState('100%');
  const inputValue = useSelector((state) => state.prepage.inputSearchbar)
  const dispatch = useDispatch();


  useEffect(() => {
    if (h1Ref.current) {
      // Calculer la largeur en pourcentage par rapport au parent
      const parentWidth = h1Ref.current.parentElement.offsetWidth;
      const h1Width = h1Ref.current.offsetWidth;

      // Calculer la largeur en pourcentage
      const calculatedWidth = (h1Width / parentWidth) * 100;
      setWidthPercent(`${calculatedWidth}%`);
      setWidthSearchPercent(`${calculatedWidth}%`)
    }
  }, [h1]);



  function createMarkup(text) {
    return {
        __html: text,
    }
}

  return (
    <div className="maincard-wrapper">
      <div id="maincard-container" className="maincard-container">
        <h1 
        ref={h1Ref}
        id="maincard-container-h1"
        dangerouslySetInnerHTML={createMarkup(h1)}
        >
        </h1>
        <div className="searchbar">
          <SearchBarMutual
          ref={searchRef}
          style={{
            width : widthSearchPercent
          }}
          placeHolder={"Je cherche un cours de couture, cuisine, aquarelle, modélisme, tricot..."}
          inputValue={inputValue}
          onChange={(event) => {
              dispatch(changeSearchbar(event.target.value))
          }}
          />
        </div>
        <div
          className="description"
          style={{ 
            width: widthPercent,
            
           }}
          dangerouslySetInnerHTML={createMarkup(description)}
         >
        </div>
      </div>
    </div>
  );
};

export default MainCard;
