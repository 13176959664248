import Header from "../../Prepage/Header/Header"
import { useEffect } from "react"

//Styles
import "./Contact.scss"
import { NavLink } from "react-router-dom"
import TrustBox from "./Trustbox/Trustbox"
import Banner from "../../Banner/Banner"

const Contact = () => {
    useEffect(() => {
        document.title = "Artesane - Apprenez à créer | Contactez-nous"
        const metaDescription = document.querySelector(
            "meta[name='description']"
        )
        metaDescription.setAttribute(
            "content",
            "Prenez des cours d'arts culinaires, d'arts du fil ou bien d'arts plastiques en ligne! Avec notre panel de cours, nous sommes votre premier atelier de loisirs créatifs exclusivement sur internet."
        )
        const metaKeywords = document.querySelector("meta[name='keywords']")
        metaKeywords.setAttribute(
            "content",
            "cours de couture en ligne, cours de broderie en ligne, cours de tricot en ligne, couture, video couture online, cours de couture video, apprendre à coudre en ligne, modelisme, stylisme, CAP couture, cours de cuisine en ligne, arts culinaires, pâtisserie, macarons, macaron, tartes, tarte, cuisson, cuissons, cuisine végétale, vegan, végétarienne, végétalienne, cuisines asiatiques, cuisine japonnaise, cuisine chinoise, cuisine italienne, arts plastiques, céramique, dessin, dessins, aquarelle, apprendre à peindre, peinture, arts du fil, broderie or, haute couture, tricot, couture homme, tailleurs"
        )
        const metaOGDescription = document.querySelector(
            "meta[property='og:description']"
        )
        metaOGDescription.setAttribute(
            "content",
            "Prenez des cours d'arts culinaires, d'arts du fil ou bien d'arts plastiques en ligne! Avec notre panel de cours, nous sommes votre premier atelier de loisirs créatifs exclusivement sur internet."
        )
        const metaOGTitle = document.querySelector("meta[property='og:title']")
        metaOGTitle.setAttribute(
            "content",
            "Artesane - Apprenez à créer | Nous contacter"
        )
        const metaOGUrl = document.querySelector("meta[property='og:url']")
        metaOGUrl.setAttribute(
            "content",
            "https://www.artesane.com" + window.location.pathname
        )
        const linkCanonical = document.querySelector("link[rel='canonical']")
        linkCanonical.setAttribute(
            "href",
            "https://www.artesane.com" + window.location.pathname
        )
        const metaRobots = document.querySelector("meta[name='robots']")
        metaRobots.setAttribute("content", "index, follow")
    }, [])
    return (
        <div>
            <Banner />
            <Header />
            <div className="contact-wrapper">
                <h1>Contactez nous, on vous répond au plus vite</h1>

                <div className="contact-mailto">
                    Une demande, une question ? Envoyez-nous un mail à{" "}
                    <a href="mailto:contact@artesane.com">
                        {" "}
                        contact@artesane.com{" "}
                    </a>
                </div>
                <div>Heures d'ouverture du service client : </div>
                <ul className="contact-hour">
                    <li>
                        <span>Lundi</span> : 8h30-16h30
                    </li>
                    <li>
                        <span>Mardi</span> : 8h30-16h30
                    </li>
                    <li>
                        <span>Mercredi</span> : 8h30-16h30
                    </li>
                    <li>
                        <span>Jeudi</span> : 8h30-16h30
                    </li>
                    <li>
                        <span>Vendredi </span> : 8h30-16h30
                    </li>
                </ul>
                <div>
                    Nous nous engageons à vous répondre dans les 2 jours ouvrés.
                </div>
                <div>
                    Avez-vous consulté notre <a href="/faq">FAQ</a> ?
                </div>
                {/* <Widget id="https://form.typeform.com/to/vED970YJ" style={{ width: '100%', height: "600px" }} className="my-form" /> */}
                <div className="contact-seo-wrapper">
                    <div>
                        Vous avez besoin d'en savoir plus sur nos cours vidéos ?{" "}
                    </div>
                    <div>
                        <p>
                            Artesane est une école en ligne de savoir-faire
                            spécialisée dans la{" "}
                            <strong>formation en ligne</strong> dans les
                            domaines de la{" "}
                            <NavLink to="/arts-culinaires/catalogue/tout/1">
                                cuisine{" "}
                            </NavLink>
                            , de la{" "}
                            <NavLink to="/arts-du-fil/catalogue/tout/1">
                                couture
                            </NavLink>
                            , et du{" "}
                            <NavLink to="/arts-plastiques/catalogue/tout/1">
                                dessin
                            </NavLink>
                            . Notre plateforme a été créée dans le but de vous
                            offrir des <strong>cours vidéos en ligne</strong> de
                            haute qualité, adaptés à tous les niveaux, que vous
                            soyez un passionné débutant ou un expert cherchant à
                            se perfectionner.
                        </p>
                        <p>
                            Nous croyons fermement au pouvoir de l'éducation
                            pour transformer les vies, que ce soit en offrant
                            des opportunités de{" "}
                            <strong>reconversion professionnelle</strong> ou
                            simplement en permettant à chacun d'explorer et de
                            développer ses talents créatifs. Avec Artesane, vous
                            pouvez accéder à une multitude de{" "}
                            <strong>formations</strong> variées, conçues et
                            animées par des experts passionnés dans leur domaine
                            respectif.
                        </p>
                        <p>
                            Que vous souhaitiez apprendre à{" "}
                            <NavLink to="/arts-culinaires/catalogue/cuisine/1">
                                cuisiner comme un chef
                            </NavLink>
                            , à{" "}
                            <NavLink to="/arts-du-fil/catalogue/couture/1">
                                coudre vos propres vêtements
                            </NavLink>{" "}
                            avec style, ou à donner vie à vos idées artistiques
                            à travers le{" "}
                            <NavLink to="/arts-plastiques/catalogue/dessin-et-peinture-dessin-tout-voir/1">
                                dessin
                            </NavLink>{" "}
                            ou l'
                            <NavLink to="/arts-plastiques/catalogue/dessin-et-peinture-peinture-aquarelle/1">
                                aquarelle
                            </NavLink>
                            , nous avons le cours parfait pour vous. Notre
                            équipe travaille sans relâche pour vous proposer un
                            contenu riche, pratique et inspirant, afin de vous
                            accompagner à chaque étape de votre parcours
                            d'apprentissage.
                        </p>
                        <p>
                            Chez Artesane, nous mettons un point d'honneur à
                            créer une communauté accueillante et bienveillante,
                            où chacun se sent libre d'explorer,et de partager
                            ses réalisations. Nos cours ne se limitent pas à
                            vous transmettre des connaissances, mais visent
                            également à vous aider à développer votre confiance
                            en vous et à stimuler votre créativité.
                        </p>

                        <p>
                            Que vous soyez en quête d'une nouvelle passion,
                            d'une <strong>reconversion professionnelle</strong>{" "}
                            ou simplement d'une manière enrichissante de passer
                            votre temps libre, Artesane est là pour vous
                            accompagner dans votre parcours. Rejoignez-nous dès
                            aujourd'hui et commencez votre aventure vers
                            l'accomplissement personnel à travers nos cours
                            vidéos en ligne de qualité supérieure.
                        </p>
                        <p>
                            Préparez les{" "}
                            <NavLink to="/arts-du-fil/produit/parcours-intensif-cap-couture-flou">
                                CAP Couture
                            </NavLink>
                            ,{" "}
                            <NavLink to="/arts-du-fil/produit/pack-cap-tailleur-1-4">
                                CAP Tailleur
                            </NavLink>
                            ,{" "}
                            <NavLink to="arts-culinaires/produit/parcours-cap-cuisine-2">
                                CAP Cuisine
                            </NavLink>{" "}
                            à votre rythme.
                        </p>
                    </div>
                </div>
                <TrustBox />
                <img
                    src={"https://static.artesane.com/images_react/favicon.png"}
                    alt="artesane"
                />
            </div>
        </div>
    )
}
export default Contact
