//Components
import { useSelector } from "react-redux"
import TitleCard from "../../Commons/Cards/TitleCard/TitleCard"

//Styles
import "./NavCard.scss"
import MainCard from "./MainCard/MainCard";

const NavCard = () => {

    const channel = useSelector((state) => state.common.channel);
    const topSliderPrepage =  useSelector((state) => state.common.topSliderPrepage);


    
    return (
    <div className="NavCard-wrapper">

        <MainCard
            h1={channel.h1 !== undefined? channel.h1 : ""}
            description = {channel.description !== undefined? channel.description : ""}
        />        

        {Object.keys(topSliderPrepage).length > 0 && (
            <>
                    {topSliderPrepage.images.map(({link, linktext, url}) => (
                    <TitleCard
                    cta={linktext}
                    image={
                        url !== undefined ? url : "https://media.artesane.com/media/cache/artesane_original/50/1d/10c88135ea2bbf0b8cc7a298a828.jpg.webp"

                    }
                    path={link}
                />
                ))}
            </>
        ) }


    </div>
)}

export default NavCard
