import './Numbers.scss';

const Numbers = () => {
    return (
        <div className='numbers-wrapper'>
            <div className='numbers-item'>
                <div className='numbers'>
                    + de 600 cours 
                </div>
                <div>
                   où vous le voulez
                </div>
                <div>
                 quand vous le voulez
                </div>
            </div>
            <div className='numbers-item'>
                <div className='numbers'>
                    200 000
                </div>
                <div>
                   élèves
                </div>
                <div>
                    conquis
                </div>
            </div>
            <div className='numbers-item'>
                <div className='numbers'>
                    Noté 4.8/5
                </div>
                <div>
                    sur Trustpilot
                </div>
            </div>
            <div className='numbers-item'>
                <div className='numbers'>
                    99% de réussite
                </div>
                <div>
                   sur nos formations certifiantes
                </div>
            </div>
        </div>
    )
}

export default Numbers;