import './MenuCard.scss';

const MenuCard = ({link, textLink, urlImg}) => {
    const words = textLink.split(" ");
    const firstPart = words.slice(0, 2).join(" ");
    const secondPart = words.slice(2).join(" ");

    return (
        <div className="menucard-wrapper">
            <a href={link}>
                <div className='menucard-image-wrapper'>
                    <img src={urlImg} alt={textLink} />
                </div>
                <div className='menucard-link-wrapper'>
                    <a href={link}>
                        <div>{firstPart}</div>
                        <div>{secondPart}</div>
                    </a>
                </div>
            </a>
        </div>
    )
}

export default MenuCard;