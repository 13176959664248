//Libraries
import ReactPlayer from "react-player"

//Styles
import "./VideoCard.scss"
import { NavLink } from "react-router-dom"

const VideoCard = () => (
    <div className="videoCard-wrapper">
        <ReactPlayer
            playsinline
            url={"https://static.artesane.com/images_react/video-prepage.mp4"}
            playing
            loop
            muted
            width={"100%"}
            height={"70vh"}
            autoPlay
        />
        <div className="videoCard-title">quel cours est fait pour moi ? </div>
        <NavLink to={"/cours-ideal"}>
            {" "}
            <div className="videoCard-link">je découvre </div>
        </NavLink>
    </div>
)

export default VideoCard
