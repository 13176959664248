import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
    fetchCoursIdeal,
    fetchProductsCoursIdeal,
    saveCommonDataInState,
} from "../../actions/commonAction"
import { selectHomeUniversActifSlug } from "../../selectors/selectors"
import Header from "../Prepage/Header/Header"
import Spinner from "../Commons/Spinner/Spinner"
import LessonCardSelection from "../Commons/Cards/LessonCardSelection/LessonCardSelection"
import "./CoursIdeal.scss"
import coursIdeal from "./perfect-lesson-form.json"; 
import Banner from "../Banner/Banner"

const CoursIdeal = () => {
    const dispatch = useDispatch()

    // const coursIdealData = useSelector((state) => state.common.coursIdealActual)
    const coursIdealPrevious = useSelector(
        (state) => state.common.coursIdealPrevious
    )
    const loading = useSelector((state) => state.common.loadingCoursIdeal)
    const loadingCoursIdealFind = useSelector(
        (state) => state.common.loadingCoursIdealFind
    )
    const coursIdealFind = useSelector((state) => state.common.coursIdealFind)

    // const coursIdealData = coursIdeal.tree; 

    useEffect(() => {
        dispatch(saveCommonDataInState("coursIdealActual", coursIdeal.tree))
        dispatch(saveCommonDataInState("coursIdeal", coursIdeal.tree))
        dispatch(fetchCoursIdeal())
    }, [dispatch, coursIdeal.tree ])
    const coursIdealData = useSelector((state) => state.common.coursIdealActual)

    function calculateAverageDepth(tree, currentDepth = 0) {
        if (!tree || !tree.choices || tree.choices.length === 0) {
            return { totalDepth: currentDepth, branchCount: 1 }; // Atteint une feuille
        }
    
        let totalDepth = 0;
        let branchCount = 0;
    
        for (const choice of tree.choices) {
            const { totalDepth: subDepth, branchCount: subCount } = calculateAverageDepth(choice, currentDepth + 1);
            totalDepth += subDepth;
            branchCount += subCount;
        }
    
        return { totalDepth, branchCount };
    }

    function getAverageDepth(tree) {
        const { totalDepth, branchCount } = calculateAverageDepth(tree);
        return totalDepth / branchCount; // Moyenne des profondeurs des chemins
    }
    const averageDepth = getAverageDepth(coursIdeal.tree);

    


    useEffect(() => {
        if (coursIdealData.products !== undefined) {
            dispatch(fetchProductsCoursIdeal(coursIdealData.products))

            document.title = "Artesane - Apprenez à créer | Mon cours idéal"
            const metaDescription = document.querySelector(
                "meta[name='description']"
            )
            metaDescription.setAttribute(
                "content",
                "Prenez des cours d'arts culinaires, d'arts du fil ou bien d'arts plastiques en ligne! Avec notre panel de cours, nous sommes votre premier atelier de loisirs créatifs exclusivement sur internet."
            )
            const metaKeywords = document.querySelector("meta[name='keywords']")
            metaKeywords.setAttribute(
                "content",
                "cours de couture en ligne, cours de broderie en ligne, cours de tricot en ligne, couture, video couture online, cours de couture video, apprendre à coudre en ligne, modelisme, stylisme, CAP couture, cours de cuisine en ligne, arts culinaires, pâtisserie, macarons, macaron, tartes, tarte, cuisson, cuissons, cuisine végétale, vegan, végétarienne, végétalienne, cuisines asiatiques, cuisine japonnaise, cuisine chinoise, cuisine italienne, arts plastiques, céramique, dessin, dessins, aquarelle, apprendre à peindre, peinture, arts du fil, broderie or, haute couture, tricot, couture homme, tailleurs"
            )
            const metaOGDescription = document.querySelector(
                "meta[property='og:description']"
            )
            metaOGDescription.setAttribute(
                "content",
                "Prenez des cours d'arts culinaires, d'arts du fil ou bien d'arts plastiques en ligne! Avec notre panel de cours, nous sommes votre premier atelier de loisirs créatifs exclusivement sur internet."
            )
            const metaOGTitle = document.querySelector(
                "meta[property='og:title']"
            )
            metaOGTitle.setAttribute(
                "content",
                "Artesane - Apprenez à créer | Mon cours idéal"
            )
            const metaOGUrl = document.querySelector("meta[property='og:url']")
            metaOGUrl.setAttribute(
                "content",
                "https://www.artesane.com" + window.location.pathname
            )
            const linkCanonical = document.querySelector(
                "link[rel='canonical']"
            )
            linkCanonical.setAttribute(
                "href",
                "https://www.artesane.com" + window.location.pathname
            )
        }
    }, [dispatch, coursIdealData])


    const width = useSelector((state) => state.common.widthProgressBarClass);

  useEffect(() => {
    // Déclenche l'animation à chaque changement de largeur
    dispatch(saveCommonDataInState("progressBarClass", "progress-bar-color"))
    const timeout = setTimeout(() =>  dispatch(saveCommonDataInState("progressBarClass", "")), 1000); // Retire la classe après l'animation
    return () => clearTimeout(timeout); // Nettoyage
  }, [width, dispatch]);


  const progressBarClass = useSelector((state) => state.common.progressBarClass);


    return (
        <div>
            <div className="progress-bar"></div>
            {/* <Banner />
            <Header /> */}
            <div className="cours-ideal-wrapper">
                <div className="progress-bar">
                    <div 
                        style={{ width: `${width <= 100 ? width : 100}%` }}
                        className={`progress-bar-color ${progressBarClass}`}
                    >

                    </div>
                </div>
                {loading && <Spinner />}
                {!loading && (
                    <div className="cours-ideal-side-wrapper">
                    <div className="side-wrapper">
                    <img src="https://media.artesane.com/media/cache/artesane_original/43/f4/bbee24b3b6c697a4be0d90b916dd.jpg.webp" alt="" />
                    </div>
                    <div className="side-wrapper"> 
                        {/* <div className="cours-ideal-title">Mon cours idéal</div> */}
                        <div className="cours-ideal-data">
                            <div className="cours-ideal-question">
                                {coursIdealData.question}
                            </div>

                            {coursIdealData.choices !== undefined &&
                                coursIdealData.choices.map(
                                    ({ title }, index) => (
                                        <div
                                            key={index}
                                            onClick={() => {
                                                coursIdealPrevious.length  === 0 ? coursIdealPrevious.push(
                                                   coursIdealData
                                                ) : coursIdealPrevious.push(
                                                    coursIdealData.choices[
                                                        index
                                                    ]
                                                    )
                                                dispatch(
                                                    saveCommonDataInState(
                                                        "coursIdealActual",
                                                        coursIdealData.choices[
                                                            index
                                                        ]
                                                    )
                                                )
                                                dispatch(
                                                    saveCommonDataInState(
                                                        "coursIdealPrevious",
                                                        coursIdealPrevious
                                                    )
                                                )
                                                if(coursIdealPrevious.length === 1) {
                                                    dispatch(
                                                        saveCommonDataInState(
                                                            "widthProgressBarClass",
                                                            width + 10
                                                        )
                                                    )
                                                } else {
                                                    dispatch(
                                                        saveCommonDataInState(
                                                            "widthProgressBarClass",
                                                            width + 10
                                                        )
                                                    ) 
                                                }
                           
                                            }}
                                            className="cours-ideal-choices">
                                            {title}
                                        </div>
                                    )
                                )}
                            {loadingCoursIdealFind &&
                                coursIdealData.products !== undefined && (
                                    <Spinner />
                                )}
                            <div className="cours-ideal-products-wrapper">
                                {coursIdealData.products !== undefined &&
                                    !loadingCoursIdealFind &&
                                    coursIdealFind.map(({ data }, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className="cours-ideal-product-card-wrapper">
                                                <LessonCardSelection
                                                    productId={data.id}
                                                    urlImg={
                                                        data.images[0]
                                                            .url_original
                                                    }
                                                    title={
                                                        data.ArtesaneProductTranslation_name
                                                    }
                                                    professor={
                                                        data.teacher_name
                                                    }
                                                    price={
                                                        data.variantsFiltres[0]
                                                            .price / 100
                                                    }
                                                    originalPrice={
                                                        data.variantsFiltres[0]
                                                            .original_price /
                                                        100
                                                    }
                                                    pack={
                                                        data.type_de_produit ===
                                                        "Packs"
                                                            ? true
                                                            : false
                                                    }
                                                    parcours={
                                                        data.type_de_produit ===
                                                        "Parcours"
                                                            ? true
                                                            : false
                                                    }
                                                    coffret={
                                                        data.type_de_produit ===
                                                        "Coffrets"
                                                            ? true
                                                            : false
                                                    }
                                                    reduction={
                                                        data.variantsFiltres[0]
                                                            .promotion
                                                    }
                                                    newer={data.nouveaute}
                                                    time={data.duree}
                                                    level={data.niveau}
                                                    key={data.niveau}
                                                    currentUnivers={selectHomeUniversActifSlug(
                                                        data.main_taxon_id
                                                    )}
                                                    productSlug={
                                                        data.ArtesaneProductTranslation_slug
                                                    }
                                                    shipedRequired={
                                                        data.variantsFiltres[0]
                                                            .shipping_required
                                                    }
                                                    variants={
                                                        data.variantsFiltres
                                                    }
                                                    buyOnce={data.buy_once}
                                                    giftCard={data.giftCard}
                                                    giftCardAmountConfigurable={
                                                        data.giftCardAmountConfigurable
                                                    }
                                                />
                                            </div>
                                        )
                                    })}
                            </div>

                            {coursIdealPrevious.length > 1 && (
                                <div
                                    className="precedent-button"
                                    onClick={() => {
                                        coursIdealPrevious.pop()
                                        window.scrollTo({
                                            top: 0,
                                            left: 0,
                                            behavior: "smooth",
                                        })
                                        dispatch(
                                            saveCommonDataInState(
                                                "loadingCoursIdealFind",
                                                true
                                            )
                                        )
                                        dispatch(
                                            saveCommonDataInState(
                                                "coursIdealActual",
                                                coursIdealPrevious[
                                                    coursIdealPrevious.length -
                                                        1
                                                ]
                                            )
                                        )
                                        dispatch(
                                            saveCommonDataInState(
                                                "coursIdealFind",
                                                []
                                            )
                                        )

                                        if(coursIdealPrevious.length === 1) {
                                            dispatch(
                                                saveCommonDataInState(
                                                    "widthProgressBarClass",
                                                    5
                                                )
                                            )
                                        } else {
                                            dispatch(
                                                saveCommonDataInState(
                                                    "widthProgressBarClass",
                                                    width - 10
                                                )
                                            )

                                        }
                                    }}>
                                    {"<"} Précedent
                                </div>
                            )}
                        </div>
                    </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default CoursIdeal
