//Styles
import "./TitleCard.scss"

//hooks
import { NavLink } from "react-router-dom"

const TitleCard = ({ title, image, path, cta }) => (
    <div className="card-item">
        <a href={path}>
            <img src={image} alt={`photographie illustrant ${title}`} />
        </a>
        <a className="nav-link" href={path}>
            <h2 className="nav-link-texte">{cta}</h2>
        </a>
    </div>
)

export default TitleCard
