import { useNavigate, useParams } from "react-router-dom"
import Header from "../../Prepage/Header/Header"
import "./ActivateCourse.scss"
import { useDispatch, useSelector } from "react-redux"
import {
    changeInputValue,
    postActivateCourse,
} from "../../../actions/userAction"
import Spinner from "../../Commons/Spinner/Spinner"
import ErrorPopup from "../ErrorPopup/ErrorPopup"
import { useEffect } from "react"
import Banner from "../../Banner/Banner"
import { changeInputAuthValue } from "../../../actions/authAction"

export const ActivateCourse = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const loadingActivateCourse = useSelector(
        (state) => state.user.loadingActivateCourse
    )
    const { token } = useParams()
    const isErrorRequest = useSelector((state) => state.user.errorRequest)

    const courseActivate = useSelector((state) => state.user.courseActivate)

    useEffect(() => {
        dispatch(changeInputValue("courseActivate", false))
        dispatch(changeInputAuthValue("", "redirectActivateCourse"));
    }, [dispatch])

    return (
        <div>
            <Banner />
            <Header />
            <div className="activatecourse-wrapper">
                {isErrorRequest && <ErrorPopup />}

                {loadingActivateCourse && <Spinner />}

                {courseActivate && (
                    <div>
                        <div className="text-description">
                            {" "}
                            Votre cours à été activé!
                        </div>
                        <div className="button-wrapper">
                            <div
                                className="button-link"
                                onClick={() => {
                                    navigate(
                                        "/mes-cours-et-documents/arts-du-fil"
                                    )
                                }}>
                                J'accède à mes cours
                            </div>
                        </div>
                    </div>
                )}

                {!courseActivate && (
                    <form
                        action="#"
                        onSubmit={(event) => {
                            event.preventDefault()
                            dispatch(
                                changeInputValue(true, "loadingActivateCourse")
                            )
                            dispatch(postActivateCourse(token))
                        }}>
                        <div className="text-description">
                            Vous vous apprêtez à activer un cours sur votre
                            compte client. Souhaitez-vous continuer ?
                        </div>
                        <div className="buttons-wrapper">
                            <button type="submit">Oui</button>
                            <div
                                className="button-link"
                                onClick={() => {
                                    navigate("/")
                                }}>
                                Non, retour à l'accueil
                            </div>
                        </div>
                    </form>
                )}
            </div>
        </div>
    )
}
