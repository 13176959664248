import { useDispatch, useSelector } from "react-redux"
import { Navigate, Outlet } from "react-router-dom"
import { changeInputAuthValue } from "../../actions/authAction";

const ProtectedRoute = ({ isAllowed, redirectPath, children }) => {

    const dispatch = useDispatch();
    
    if (!isAllowed) {
        if(window.location.pathname) {
            dispatch(changeInputAuthValue(window.location.pathname, "redirectActivateCourse"));
        }
        return <Navigate to={redirectPath} replace />
    }

    return children ? children : <Outlet />
}

export default ProtectedRoute
