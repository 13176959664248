import MediaQuery from 'react-responsive'
import './Reviews.scss'
import AliceCarousel from "react-alice-carousel"

const Reviews = () => {
    return (
        <>
     
            <div className='reviews-wrapper'>
                <div className='review-wrapper-title'>
                    <div>
                        Vos avis sur nos cours
                    </div>
                    
                </div>
                <MediaQuery minWidth={601}>
                <div className='reviews-items-wrapper'>
                    <div className='reviews-item'>
                        <div className='review-rating'> 
                            <img src="https://cdn.trustpilot.net/brand-assets/4.1.0/stars/stars-5.svg" alt=" 5 étoiles" />
                        </div>
                        <div className='reviews-title'>
                            Préparation du CAP couture et achat…
                        </div>
                        <div className='review-content'>
                            Préparation du CAP couture et achat divers cours couture, lingerie, modélisme et broderie. Très bons cours avec professionnels de hauts niveaux de pratiques et soucieux de former avec exigence, dans un esprit apaisé pour une formation et un avenir sûrs et prometteurs. Recommandé sans retenue.
                        </div>
                        <div className='review-author'>
                            Anne 
                        </div>
                    </div>
                </div>

                <div className='reviews-items-wrapper'>
                    <div className='reviews-item'>
                        <div className='review-rating'> 
                            <img src="https://cdn.trustpilot.net/brand-assets/4.1.0/stars/stars-5.svg" alt=" 5 étoiles" />
                        </div>
                        <div className='reviews-title'>
                            Meilleure plate-forme de formation
                        </div>
                        <div className='review-content'>
                        Un choix énorme de formations dans divers domaines ! J'ai acheté beaucoup de cours en couture et modélisme. Tout est parfait : tant le contenu que le professionnel qui dispense le cours ou encore les supports de cours. Je recommande vivement Artesane et surtout un grand merci au service client qui est toujours à l'écoute. Régulièrement des promotions permettent de se laisser tenter à moindre coût.
                        </div>
                        <div className='review-author'>
                        Cathy 
                        </div>
                    </div>
                </div>

                <div className='reviews-items-wrapper'>
                    <div className='reviews-item'>
                        <div className='review-rating'> 
                            <img src="https://cdn.trustpilot.net/brand-assets/4.1.0/stars/stars-5.svg" alt=" 5 étoiles" />
                        </div>
                        <div className='reviews-title'>
                            Des cours de grande qualité
                        </div>
                        <div className='review-content'>
                            Je suis devenue une inconditionnelle des cours artesane! J’ai appris tellement de choses! Je suis particulièrement férue des cours de Marie Laure qui nous apprend beaucoup de techniques précises et de finitions parfaites. J’ai appris à stocker des robes de petites filles, faire des plis     religieuses et j’en passe. Tout est très bien expliqué et on y arrive vraiment. J’ai également eu à Noël le coffret broderie or et j’adore m’initier à cette nouvelle technique. Bref, je recommande les yeux fermés
                        </div>
                        <div className='review-author'>
                        Sarah 
                        </div>
                    </div>
                </div>
                </MediaQuery>
                 
                <MediaQuery maxWidth={600}>
                <AliceCarousel
                    // infinite
                    // autoPlay={true}
                    items={[
                    <div className='reviews-items-wrapper'>
                            <div className='reviews-item'>
                                <div className='review-rating'> 
                                    <img src="https://cdn.trustpilot.net/brand-assets/4.1.0/stars/stars-5.svg" alt=" 5 étoiles" />
                                </div>
                                <div className='reviews-title'>
                                    Des cours de grande qualité
                                </div>
                                <div className='review-content'>
                                    Je suis devenue une inconditionnelle des cours Artesane ! J’ai appris tellement de choses ! Je suis particulièrement férue des cours de Marie-Laure qui nous apprennent beaucoup de techniques précises et de finitions parfaites. J’ai appris à smocker des robes de petites filles, faire des plis religieuses et j’en passe. Tout est très bien expliqué et on y arrive vraiment. J’ai également eu à Noël le coffret broderie or et j’adore m’initier à cette nouvelle technique. Bref, je recommande les yeux fermés.
                                </div>
                                <div className='review-author'>
                                Sarah 
                                </div>
                            </div>
                        </div>,
                        <div className='reviews-items-wrapper'>
                            <div className='reviews-item'>
                                <div className='review-rating'> 
                                    <img src="https://cdn.trustpilot.net/brand-assets/4.1.0/stars/stars-5.svg" alt=" 5 étoiles" />
                                </div>
                                <div className='reviews-title'>
                                    Préparation du CAP couture et achat…
                                </div>
                                <div className='review-content'>
                                    Préparation du CAP couture et achat divers cours couture, lingerie, modélisme et broderie. Très bons cours avec professionnels de haut niveau de pratique et soucieux de former avec exigence, dans un esprit apaisé pour une formation et un avenir sûr et prometteur. Recommandé sans retenue.
                                </div>
                                <div className='review-author'>
                                    Anne 
                                </div>
                            </div>
                        </div>, 
                        <div className='reviews-items-wrapper'>
                            <div className='reviews-item'>
                                <div className='review-rating'> 
                                    <img src="https://cdn.trustpilot.net/brand-assets/4.1.0/stars/stars-5.svg" alt=" 5 étoiles" />
                                </div>
                                <div className='reviews-title'>
                                    Meilleure plateforme de formation
                                </div>
                                <div className='review-content'>
                                Un choix énorme de formations dans divers domaines ! J'ai acheté beaucoup de cours en couture et modélisme. Tout est parfait : tant le contenu que le professionnel qui dispense le cours ou encore les supports de cours. Je recommande vivement Artesane et surtout un grand merci au service client qui est toujours à l'écoute. Régulièrement des promotions permettent de se laisser tenter à moindre coût.
                                </div>
                                <div className='review-author'>
                                Cathy 
                                </div>
                            </div>
                        </div>,


                    ]}
                    responsive={{
                        0: {
                            items: 1,
                        },
                        1024: {
                            items: 3,
                        },
                        1600: {
                            items: 4,
                        },
                    }}
                />
                </MediaQuery>
            </div>
      
        </>

    )
}

export default Reviews;