import { useSelector } from "react-redux";
import "./Banner.scss"

const Banner = ({ univers }) => {

    const loadingAllUniverses = useSelector((state) => state.common.loadingAllUniverses);
    const bannerSlider = useSelector((state) => state.common.bannerSlider);
    

    return (
        <div className="banner-wrapper">
            <div className="banner-text">
                { !loadingAllUniverses  && (
                
                <>
                    {Object.keys(bannerSlider).length > 0 && (
                        <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={bannerSlider.images.length > 0 ? bannerSlider.images[0].link : "https://mailchi.mp/artesane/inscription-newsletter-artesane-jupe"}>
                            <span>
                                {bannerSlider.images.length > 0 ? bannerSlider.images[0].linktext : "Votre patron et cours offerts en vous inscrivant à la newsletter !"}
                            </span>
                        </a>
                    )}
                    {Object.keys(bannerSlider).length === 0  && (
                        <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={"https://mailchi.mp/artesane/inscription-newsletter-artesane-jupe"}>
                            <span>Votre patron et cours offerts en vous inscrivant à la newsletter !</span>
                        </a>
                    )}
                </>
                )}

            </div>
        </div>
    )
}

export default Banner
