// Components
import Header from "./Header/Header"
import NavCard from "./NavCard/NavCard"
import Reinsurance from "./Reinsurance/Reinsurance"
import VideoCard from "./VideoCard/VideoCard"
import Banner from "../Banner/Banner"

import "./Prepage.scss"

import SeoText from "./SeoText/SeoText"
import { useEffect } from "react"
import {
    isLoadingProduct,
    saveProductDataState,
} from "../../actions/productsActions"
import { useDispatch, useSelector } from "react-redux"
import Spinner from "../Commons/Spinner/Spinner"
import Reviews from "./Reviews/Reviews"
import Numbers from "./Numbers/Numbers"
import LessonsSlider from "../Home/LessonsSlider/LessonsSlider"
import MenuCards from "./MenuCards/MenuCards"
import NewsletterForm from "./NewsletterForm/NewsletterForm"

const Prepage = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(isLoadingProduct(true))
        dispatch(saveProductDataState("notFoundProduct", false))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const loadingAllUniverses = useSelector((state) => state.common.loadingAllUniverses);
    const channel = useSelector((state) => state.common.channel);
    const productSliderPrepage = useSelector((state) => state.common.productSliderPrepage);

    

    return (
        <>
            <Banner />
            <Header />
            {loadingAllUniverses && (
                <Spinner/>
            )}
            {!loadingAllUniverses && (
                <NavCard />
            )}
            <Reviews/>
            <Numbers/>
            {/* <VideoCard/> */}


            {!loadingAllUniverses && (
                <MenuCards/>
            )}

            {!loadingAllUniverses && (
             <NewsletterForm/>
            )}

            {!loadingAllUniverses &&  Object.keys(productSliderPrepage).length > 0 &&(
                    <LessonsSlider
                    page={"prepage"}
                     selectLessons={productSliderPrepage}
                />
            )}
            <div className="reinssurance-and-title-wrapper">
                <div className="reinssurance-title">Pourquoi choisir Artesane ?</div>
                <div className="reinssurance-wrapper">
            
                    <Reinsurance
                        logo={
                            "https://static.artesane.com/images_react/picto-prepage1.png"
                        }
                        altImg={"bobine de fil"}
                        text={"Un large choix de cours loisirs à la carte, accessibles sans limite et adaptés à tous"}
                        underText={"Plongez dans un vaste catalogue de formations créatives de + de 600 cours, conçus pour tous les niveaux. Apprenez à votre rythme, où vous le voulez, quand vous le souhaitez."}
                    />
                    <Reinsurance
                        logo={
                            "https://static.artesane.com/images_react/picto-prepage2.png"
                        }
                        altImg={"bobine de fil"}
                        text={"Des formations professionnelles certifiantes, pour transformer sa passion en métier"}
                        underText={"Faites valoir vos compétences grâce à des parcours complets, validés par des experts passionnés. Acquérez toutes les connaissances nécessaires pour réussir votre reconversion, exercer en tant que professionnel et donner un nouvel élan à votre carrière."}
                    />
                    <Reinsurance
                        logo={
                            "https://static.artesane.com/images_react/picto-prepage3.png"
                        }
                        altImg={"bobine de fil"}
                        text={"Des vidéos de haute qualité, capturant chaque geste en détail"}
                        underText={"Observez chaque technique de près grâce à nos caméras en haute définition. Les plans rapprochés et les explications pas à pas vous aident à comprendre et reproduire parfaitement chaque étape."}
                    />
                    <Reinsurance
                        logo={
                            "https://static.artesane.com/images_react/picto-prepage3.png"
                        }
                        altImg={"bobine de fil"}
                        text={"Une communauté engagée et des experts passionnés pour vous accompagner"}
                        underText={"Rejoignez un réseau d’entraide où passionnés et professionnels partagent leurs astuces, encouragent vos progrès et vous soutiennent tout au long de votre parcours de formation."}
                    />
                </div>
            </div>


            <SeoText />
        </>
    )
}

export default Prepage
