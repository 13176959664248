import "./SeoText.scss"

const SeoText = () => {
    return (
        <div className="seotext-wrapper">
            <div>
                <p>
                    Artesane est la{" "}
                    <strong>
                        première école de cours d’arts et de loisirs créatifs en
                        ligne
                    </strong>
                    . Nous vous apprenons les arts du fil, les arts culinaires
                    et les beaux-arts. Champions des{" "}
                    <strong>cours de couture</strong>, des{" "}
                    <strong>cours de cuisine</strong> et de{" "}
                    <strong>pâtisserie</strong> ou des{" "}
                    <strong>cours d’aquarelle en ligne</strong>, . nous vous
                    proposons de suivre nos cours vidéo pas-à-pas, à votre
                    rythme!
                </p>
                <p>
                Dans votre espace élève, accédez à vos cours chapitre après chapitre, prenez des notes et visionnez les séquences techniques autant de fois que nécessaire. Nos professeurs d’excellence, issus des meilleurs domaines artisanaux et artistiques, vous accompagnent dans votre apprentissage, que vous soyez débutant ou expert.
                </p>

                <p>
                Découvrez un large choix de formations en ligne couvrant divers métiers et savoir-faire : broderie, tricot, dessin, photographie, calligraphie, cuisine, artisanat et bien d’autres. Nos cours vont bien au-delà de simples tutoriels : ils vous offrent des outils concrets pour développer vos compétences, que ce soit dans un cadre personnel ou professionnel.
                </p>
                <p>
                    <strong>Mode & textile</strong> :Cousez vos propres vêtements, maîtrisez la couture et explorez les différentes techniques textiles, du jersey au denim. Nos formations couvrent aussi la broderie, la tapisserie, la dentelle, le crochet  et le tricot. Elles vous permettent de découvrir aussi le stylisme, le modélisme et le moulage.
                </p>
                <p>
                    <strong>Dessin, peinture & décoration </strong> : Du croquis au digital, perfectionnez votre trait, explorez l’aquarelle, la gouache ou la calligraphie pour embellir vos créations. Apprenez à concevoir des objets décoratifs et développez un univers artistique unique
                </p>
                <p>
                    <strong>Photographie & image</strong> :  ALancez-vous ou perfectionnez vous en photographie. Apprenez à mettre en valeur vos sujets ou réalisations et développez votre technique photo sur des appareils relex ou sur smartphone.
                </p>
                <p>
                    <strong>Cuisine & pâtisserie</strong> :  Découvrez la cuisine, perfectionnez vos techniques pâtissières et apprenez des gestes professionnels.
                </p>
                <p>
                Nos cours en ligne sont conçus pour s’adapter à vos ambitions : que vous soyez salarié en reconversion, artisan indépendant ou passionné de DIY, vous trouverez des formations adaptées à votre projet. Nous vous accompagnons dans la gestion de votre activité et vous donnons toutes les informations nécessaires pour faire de votre passion un emploi ou un complément de revenus. Artesane se distingue par une approche sur mesure et un accompagnement approfondi, favorisant l’épanouissement personnel et professionnel dans tous les domaines artisanaux.
                </p>
            </div>
        </div>
    )
}

export default SeoText
